<template lang="pug">
  .base-modal(:class="[align ? 'text-' + align : '', isActive ? 'active' : '']")
    .base-modal__overlay.overlay(@click="close")
    .base-modal__window
      button.link.base-modal__close(type="button" @click="close")
        icon(icon-name='close' w="20" h="20")
      .base-modal__container
        slot
</template>

<script>
export default {
  name: 'Modal',
  data() {
    return {
      isActive: false,
    };
  },
  props: {
    name: {
      type: String,
    },
    title: String,
    subtitle: String,
    align: String,
    _isActive: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    open() {
      this.$emit('beforeOpen');
      this.isActive = true;
      document.body.style.overflowY = 'hidden';
      document.body.classList.add('has-modal');
    },
    close() {
      const vm = this;
      this.isActive = false;
      document.body.style.overflowY = '';
      document.body.classList.remove('has-modal');

      setTimeout(() => {
        vm.$emit('afterClose');
      }, 200);
    },
  },
  watch: {
    $route(newRoute, oldRoute) {
      if (oldRoute.name && newRoute.name !== oldRoute.name && this.isActive) {
        this.close();
      }
    },
  },
  created() {
    // eslint-disable-next-line no-underscore-dangle
    if (this._isActive) {
      this.open();
    }
    if (this.name) {
      this.$root.$modals[this.name] = this;
    }
  },
  beforeDestroy() {
    if (this.isActive) {
      this.close();
    }
  },
};
</script>

<style lang="scss">
  @import "@/assets/scss/components/modal";
</style>
