<template lang="pug">
  Modal(
    name="offline-reg-modal"
    align="center"
    ref="modal"
  )
    .base-modal__heading.base-modal__item
      .base-modal__top-img
        img(:src="themeImages['to-do-list']" alt="")
      h3.base-modal__title Оффлайн регистрация
      .base-modal__subtitle Дорогой друг, электронная регистрация уже закрыта.
        |
        | Зарегистрироваться можно в&nbsp;месте выдачи стартовых пакетов.
</template>

<script>
import { mapGetters } from 'vuex';
import Modal from '@/components/Modal.vue';

export default {
  name: 'OfflineRegModal',
  components: {
    Modal,
  },
  computed: {
    ...mapGetters('settings', ['themeImages']),
  },
};
</script>
