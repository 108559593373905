export default {
  enabled: [
    'discipline',
    'dateFrom',
    'dateTo',
    'location',
    'hasPlaces',
    'ageFrom',
    'ageTo',
  ],
};
