import './assets/scss/main.scss';
import moment from 'moment';
import Vue from 'vue';
import { VPopover, VTooltip } from 'v-tooltip';
import Clipboard from 'v-clipboard';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
// Components
import icon from './components/Icon.vue';
import Dropdown from './components/Dropdown.vue';

VTooltip.options.popover.defaultBaseClass = 'base-tooltip base-popover';
VTooltip.options.popover.defaultWrapperClass = 'popover-wrapper';

Vue.component('Icon', () => import('@rr-component-library/icon/src/main'));
Vue.component('Button', () => import('@rr-component-library/button/src/main'));
Vue.component('Checkbox', () => import('@rr-component-library/checkbox/src/main'));
Vue.component('Radio', () => import('@rr-component-library/radio/src/main'));
Vue.component('RrLink', () => import('@rr-component-library/link/src/main'));

Vue.component('icon', icon);
Vue.component('Dropdown', Dropdown);
Vue.component('v-popover', VPopover);
Vue.use(Clipboard);

Vue.prototype.$env = window.VueEnv || {
  Championships: 'https://champs.russiarunning.com',
  Clubs: 'https://clubs.russiarunning.com',
  RussiaRunning: 'https://russiarunning.com',
};

Vue.prototype.$moment = moment;

Vue.config.productionTip = false;
Vue.config.devtools = !!localStorage.getItem('vue-debug');

// eslint-disable-next-line no-underscore-dangle
i18n.locale = window.__LANGUAGE__ ?? process.env.VUE_APP_I18N_LOCALE;
i18n.pluralizationRules.ru = (val) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return (val % 100 > 4 && val % 100 < 20) ? 2 : cases[(val % 10 < 5) ? val % 10 : 5];
};
Vue.prototype.$lang = i18n.locale;

moment.locale(i18n.locale);

Vue.directive('click-outside', {
  bind(el, binding) {
    const { bubble } = binding.modifiers;
    const handler = (e) => {
      if (bubble || (!el.contains(e.target) && el !== e.target)) {
        binding.value(e);
      }
    };
    // eslint-disable-next-line no-param-reassign,no-underscore-dangle
    el.__vueClickOutside__ = handler;
    document.addEventListener('click', handler);
  },
});

const Main = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

Main.locale = i18n.locale;

Vue.directive('open-modal', (el, binding) => {
  el.addEventListener('click', (e) => {
    e.preventDefault();
    const modal = Main.$modals[binding.value.name];
    if (modal) {
      modal.open();
    }
  });
});
