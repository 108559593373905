<template>
  <div class="top-alerts" v-if="isActive">
    <div class="top-alerts__container">
      <div class="top-alerts__item">
        <Alert
          class="draft-alert rr-alert_draft"
          stacked
          no-icon
          no-action
        >
          <template v-slot:text>
            <b>{{ $t('draft.message') }}</b>
            <a
              :href="draft.eventUrl"
              class="link">{{ draft.eventName }}
            </a>
          </template>
          <template v-slot:buttons>
            <div class="draft-alert__buttons">
              <Button
                variant="primary"
                size="sm"
                mobile
                :href="draft.registerLink"
                :text="$t('draft.continue')"
                icon-right="right"
                class="draft-alert-button"
              ></Button>
              <Button
                variant="ghost"
                view="danger"
                size="sm"
                mobile
                :text="$t('draft.delete')"
                @click="deleteDraft"
                class="draft-alert-button"
              ></Button>
            </div>
          </template>
        </Alert>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Alert from '@rr-component-library/alert/src/main';

export default {
  name: 'DraftAlert',
  components: {
    Alert,
  },
  data() {
    return {
      draft: this.$root.draft,
      isMobile: false,
    };
  },
  computed: {
    isActive() {
      return this.draft;
    },
  },
  methods: {
    deleteDraft() {
      if (!this.draft.isLocal) {
        axios.post('/Register/ApiClearCart');
      }
      localStorage.removeItem('registerDraft');
      this.draft = null;
      this.$root.draft = null;
    },
    getDraft() {
      axios.get('/Register/ApiCardInfo')
        .then((response) => {
          const res = response.data;
          if (res.HasDraft) {
            this.draft = {
              isLocal: false,
              eventUrl: res.Draft.EventUrl,
              eventName: res.Draft.EventName,
              registerLink: res.Draft.RegistrationUrl,
            };
          } else {
            const serialized = localStorage.getItem('registerDraft');
            if (!serialized) {
              return;
            }
            const draft = JSON.parse(serialized);
            const forCurrentUser = window.userInfo.Id === draft.userId;
            if (!forCurrentUser) {
              localStorage.removeItem('registerDraft');
              return;
            }
            this.draft = {
              isLocal: true,
              eventUrl: `/event/${draft.eventCode}`,
              eventName: draft.eventName,
              registerLink: `/${draft.eventCode}/${draft.raceCode}/Register`,
            };
          }
          this.$root.draft = this.draft;
        });
    },
  },
  created() {
    if (window.userInfo) {
      this.getDraft();
    }
  },
  mounted() {
    this.isMobile = window.innerWidth < 768;
  },
};
</script>

<style lang="scss">
.top-alerts {
  margin: 24px 0;

  &__container {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    margin-top: -12px;
  }

  .top-alerts__item {
    margin-top: 12px;
  }
}

.draft-alert-button {
  white-space: nowrap;
}

.rr-alert.rr-alert_draft {
  background-color: $color-primary-1;
  border-color: $color-primary-3;
  color: $color-black;
}

.draft-alert {

  &__buttons {
    width: 100%;
    flex: 0 0 auto;
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-left: -8px;
    margin-top: -8px;

    .rr-button {
      width: 100%;
      margin-left: 8px;
      margin-top: 8px;
    }

    @media (min-width: breakpoint(sm)) {
      flex-direction: row-reverse;
      flex-wrap: nowrap;
      width: auto;

      .rr-button {
        width: auto;
      }
    }
  }
}
</style>
