<template>
  <div class="event-card">
    <div class="event-card__container">
      <div class="event-card__cover">
        <div
          class="event-card__cover-img"
          v-show="image"
          :class="{'d-none d-md-block': mobileImage}"
          :style="{ backgroundImage: `url(${image})` }"
        ></div>
        <div
          class="event-card__cover-img d-md-none"
          v-show="mobileImage"
          :style="{ backgroundImage: `url(${mobileImage})` }"
        ></div>
        <router-link
          class="event-card__cover-link"
          :to="{ name: 'Event', params: { code: eventCode, event: eventData } }"
        ></router-link>
        <div class="event-card__badges" v-if="!badges.empty">
          <div class="event-card__badges-item" v-if="badges.rrr">
            <v-popover placement="top-center" trigger="hover">
              <img
                src="@/assets/img/badges/recommended-rr.svg"
                alt="RR"
              >
              <template slot="popover">
                <div v-html="$t('race.rrTooltip')"></div>
              </template>
            </v-popover>
          </div>
          <div class="event-card__badges-item" v-if="badges.sportmaster">
            <v-popover placement="top-center" trigger="hover">
              <img
                src="@/assets/img/badges/sportmaster-icon.svg"
                alt="SM"
              >
              <template slot="popover">
                <div v-html="$t('race.sportmasterTooltip')"></div>
              </template>
            </v-popover>
          </div>
          <div class="event-card__badges-item" v-if="badges.nrm">
            <v-popover placement="top-center" trigger="hover">
              <img
                src="@/assets/img/badges/nbd.svg"
                alt="NBD"
              >
              <template slot="popover">
                <div v-html="$t('race.nroTooltip')"></div>
              </template>
            </v-popover>
          </div>
          <div class="event-card__badges-item" v-if="badges.sr || badges.asr">
            <v-popover placement="top-center" trigger="hover">
              <div
                class="stars"
                :class="{'stars_approved': badges.asr}"
              >
                <icon icon-name="star"></icon>
                <span class="stars__text">{{ badges.asr || badges.sr }}</span>
              </div>
              <template slot="popover">
                <div
                  v-html="$t(badges.asr ? 'race.starRatingApproved' : 'race.starRating')"
                ></div>
              </template>
            </v-popover>
          </div>
          <div
            class="event-card__badges-item"
            v-if="badges.pra"
          >
            <v-popover
              placement="top-center"
              trigger="hover"
              popoverClass="member-rating-popup"
            >
              <badge
                class="member-rating-badge badge_circle"
                :text="badges.pra"
              >
              </badge>
              <template slot="popover">
                <div v-html="$t('race.praTooltip')"></div>
              </template>
            </v-popover>
          </div>
        </div>
      </div>
      <div class="event-card__info">
        <div class="event-card__header">
          <div class="event-card__header-col">
            <b class="color-primary" v-html="eventDate"></b>
          </div>
          <div
            class="event-card__header-col d-xl-none"
            v-if="results || eventRegistration.noPlaces || eventRegistration.fewPlaces"
          >
            <img
              v-if="isLive"
              src="@/assets/img/icons/live-logo.svg"
              alt="Live"
              width="44"
            >
            <badge
              v-else-if="results"
              class="badge_primary"
              :text="$t('race.results')"
              type="muted"
            >
            </badge>
            <badge
              v-else-if="eventRegistration.noPlaces"
              :text="$t('race.noPlaces')"
              type="muted"
            >
            </badge>
            <badge
              v-else-if="eventRegistration.fewPlaces"
              :text="$t('race.fewPlaces')"
              type="warning"
              icon="fire"
            >
            </badge>
          </div>
        </div>
        <div class="event-card__body">
          <div class="event-card__body-item">
            <router-link
              class="event-card__name"
              v-html="name"
              :title="name"
              :to="{ name: 'Event', params: { code: eventCode, event: eventData } }"
            ></router-link>
          </div>
          <div class="event-card__body-item" v-if="hasAdmissions && !results">
            <badge
              class="badge_circle badge_text color-success"
              size="md"
              :text="$t('race.hasFastId')"
              type="light"
              icon="file-done"
            />
          </div>
        </div>
        <div class="event-card__footer">
          <!-- временно скрыл погоду -->
          <!-- <div
            class="event-card__footer-item"
            v-if="!results && event.d"
          >
            <weather-badge
              :lat="event.lat"
              :lon="event.lon"
              :date="event.d"
              :timezone="event.tz"
              size="sm"
            ></weather-badge>
          </div> -->
          <div
            class="event-card__footer-item event-card__footer-item_main"
            v-if="location"
          >
            <div class="text-with-icon">
              <icon
                class="icon color-muted"
                icon-name="environment"
                w="16"
                h="16"
              />
              <span
                class="event-card__location"
                :title="location"
              >{{ location }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="event-card__actions"
        v-if="hasCompetitions"
      >
        <div class="event-card__actions-item">
          <Select
            class="multiselect_wrap"
            v-model="race"
            :value="race"
            :options="racesOptions"
            :iconName="raceIcon"
            @input="onRaceChange"
          ></Select>
        </div>
        <div
          class="event-card__actions-item"
          v-if="results"
        >
          <b class="h3-m text-with-icon text-nowrap">
            <icon class="icon" icon-name="user-groups" w="20" h="20"></icon>
            <span>{{ raceInfo.pc || 0 }}</span>
          </b>
        </div>
        <div
          class="event-card__actions-item"
          v-else
        >
          <div
            class="event-card__cost h3-m m-0"
            :class="{
              'event-card__cost_default': registration.offlineAvailable,
              'event-card__cost_muted': !registration.offlineAvailable
              && (!registrationAvailable || noPlaces)
            }"
          >
            <div>
              <template v-if="racePrice && raceBasePrice && registrationAvailable && !noPlaces">
                <b class="d-inline-block text-sm color-muted"><s>{{ raceBasePrice }}&nbsp;{{ currency }}</s>&nbsp;</b>&nbsp;<span>{{ racePrice }}&nbsp;{{ currency }}</span>
                <span v-if="showParticipationPrice" class="text-sm color-muted">/&nbsp;{{ $t('race.priceParticipant') }}</span>
              </template>
              <template v-else-if="racePrice">
                {{ racePrice }}&nbsp;{{ currency }}
                <span v-if="showParticipationPrice" class="text-sm color-muted">/&nbsp;{{ $t('race.priceParticipant') }}</span>
              </template>
              <template v-else>{{ $t('race.free') }}</template>
            </div>
            <v-popover
              class="event-card__cost-tooltip"
              placement="top-center"
              trigger="hover"
              v-if="eventInfo.hasCommission && eventInfo.commission"
              v-show="racePrice && registrationAvailable && !noPlaces"
            >
              <icon class="icon color-muted" icon-name="info-circle" w="20" h="20"></icon>
              <template slot="popover">
                <div v-html="$t('event.slotCommission', [`${eventInfo.commission.toLocaleString($lang)}%`])"></div>
              </template>
            </v-popover>
          </div>
          <template v-if="!registration.offlineAvailable">
            <div
              class="event-card__status color-muted"
              v-if="!registration.notOpened && (!registrationAvailable || noPlaces)"
            >
              <b>{{ $t('race.noPlaces') }}</b>
            </div>
            <!-- Dynamic price by date hint : BEGIN -->
            <div
              class="event-card__status"
              v-else-if="dynamicPriceHintAvailable && raceInfo.prt === 4"
            >
              <b v-html="`${$tc('race.days', daysBeforeNewPrice || 1)} ${$tc('race.beforePriceIncrease', 'color-danger')}`"></b>
            </div>
            <!-- Dynamic price by date hint : END -->
            <!-- Dynamic price by registrations hint : BEGIN -->
            <div
              class="event-card__status"
              v-else-if="dynamicPriceHintAvailable && raceInfo.prt === 5"
            >
              <b v-html="`${$tc('race.places', raceInfo.ph)} ${$tc('race.beforePriceIncrease', 'color-danger')}`"></b>
            </div>
            <!-- Dynamic price by registrations hint : END -->
            <div
              class="event-card__status text-with-icon"
              v-else-if="!raceInfo.hapc && registration.fewPlaces"
            >
              <icon
                class="icon"
                icon-name="fire"
                w="14"
                h="14"
              />
              <b v-html="$tc('race.placesLeft', registration.count || 0)"></b>
            </div>
            <div
              class="event-card__status"
              v-else-if="!raceInfo.hapc && registration.count"
              v-html="$tc('race.placesLeft', registration.count)"
            >
            </div>
          </template>
        </div>
        <div class="event-card__actions-item">
          <Button
            v-if="raceInfo.isLive"
            variant="primary"
            view="danger"
            wide
            :href="liveLink"
            target="_blank"
            :text="$t('race.liveStream')"
            icon-right="play"
            key="live"
          ></Button>
          <Button
            v-else-if="results"
            variant="ghost"
            wide
            :href="resultsLink"
            target="_blank"
            :text="$t('race.results')"
            icon-right="right"
          ></Button>
          <div
            class="rr-buttons-group rr-buttons-group_flex"
            v-else-if="isMember"
          >
            <Button
              variant="ghost"
              view="success"
              disabled-quiet
              :text="$t('event.isMember')"
              icon-right="check-bold"
              style="flex-grow: 1;"
            ></Button>
            <Button
              v-if="registrationAvailable && !noPlaces && !raceInfo.dfr"
              variant="primary"
              :href="registerLink"
              target="_blank"
              text="+1"
            ></Button>
          </div>
          <Button
            v-else-if="registration.notOpened"
            variant="ghost"
            wide
            key="soonBtn"
            disabled
            :text="$t('race.registrationSoon')"
          ></Button>
          <Button
            v-else-if="registration.offlineAvailable"
            variant="secondary"
            wide
            v-open-modal="{ name: 'offline-reg-modal' }"
            :text="$t('race.offlineRegistration')"
            key="offlineBtn"
          ></Button>
          <Button
            v-else-if="!registrationAvailable || noPlaces"
            variant="ghost"
            wide
            disabled
            :text="$t('race.registrationClosed')"
            key="closedBtn"
          ></Button>
          <Button
            v-else
            variant="ghost"
            wide
            :href="registerLink"
            target="_blank"
            :text="$t('race.registration')"
            icon-right="right"
            key="regBtn"
          ></Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Badge from '@/components/Badge.vue';
import Select from '@/components/Select.vue';
// import WeatherBadge from '@/components/WeatherBadge.vue';
import { formatDateRange, getCroppedImg } from '@/assets/js/helpers';
import { sportTypesMixin } from '@/assets/js/mixins';
import {
  filter, map, some,
} from 'lodash';

export default {
  name: 'EventCard',
  components: {
    Badge,
    Select,
    // WeatherBadge,
  },
  props: {
    eventInfo: Object,
    results: Boolean,
    registrations: Array,
  },
  mixins: [sportTypesMixin],
  data() {
    return {
      event: {},
      eventCode: null,
      name: null,
      location: null,
      races: [],
      race: null,
      racesOptions: [],
      racesInfo: {},
      image: null,
      mobileImage: null,
      registration: {},
      eventRegistration: {
        noPlaces: false,
        fewPlaces: false,
      },
      badges: {},
    };
  },
  computed: {
    ...mapGetters('settings', ['themeCurrency']),
    eventData() {
      if (!this.image) {
        return null;
      }
      return {
        name: this.name,
        image: this.event.hiu,
        beginDate: this.event.d,
        endDate: this.event.ed,
        location: this.location,
        badges: this.badges,
        discipline: this.event.dc,
      };
    },
    eventDate() {
      const { d, ed } = this.event;

      const locale = this.$lang === 'uz' ? 'uz-latn' : this.$lang;
      this.$moment.locale(locale);

      return formatDateRange([
        this.$moment(d),
        this.$moment(ed),
      ]);
    },
    userRaces() {
      const { registrations } = this;
      if (!registrations || registrations.length < 1) {
        return [];
      }
      return map(registrations, 'RaceId');
    },
    isLive() {
      return this.races.some((r) => r.isLive);
    },
    isMember() {
      if (this.userRaces.length < 1) {
        return false;
      }
      const raceId = this.raceInfo.id;
      return raceId && this.userRaces.indexOf(raceId) !== -1;
    },
    hasAdmissions() {
      return some(this.races, (o) => o.nas);
    },
    raceInfo() {
      if (!this.race) {
        return {};
      }
      return this.racesInfo[this.race] || {};
    },
    raceIcon() {
      const disciplineCode = this.raceInfo.dc;
      if (!disciplineCode) {
        return null;
      }
      const discipline = this.sportTypesImages[disciplineCode];
      return discipline ? discipline.icon : null;
    },
    currency() {
      return this.themeCurrency;
    },
    racePrice() {
      return this.raceInfo.p;
    },
    showParticipationPrice() {
      return this.raceInfo.ShowParticipationPrice;
    },
    raceBasePrice() {
      if (this.raceInfo.hbp) {
        return null;
      }
      const bp = this.raceInfo.bp || 0;
      return bp > this.racePrice ? bp : null;
    },
    registerLink() {
      const url = window.location.origin;
      const { eventCode } = this;
      const raceCode = this.raceInfo.c || this.raceInfo.id;
      return `${url}/${eventCode}/${raceCode}/Register`;
    },
    liveLink() {
      const { eventCode } = this;
      const raceCode = this.raceInfo.c || this.raceInfo.id;
      return `${this.$env.Live}/?code=${eventCode}&race=${raceCode}`;
    },
    resultsLink() {
      const { eventCode } = this;
      const raceCode = this.raceInfo.c || this.raceInfo.id;
      return `${this.$env.Results}/event/${eventCode}/results/${raceCode}`;
    },
    hasCompetitions() {
      return this.races.length > 0;
    },
    noPlaces() {
      return this.raceInfo.rc === 0;
    },
    registrationAvailable() {
      return this.getRegistrationAvailable(this.registration);
    },
    dynamicPriceHintAvailable() {
      return !this.registration.notOpened && this.registrationAvailable && !this.noPlaces
        && this.raceBasePrice
        && this.raceInfo.ph && this.raceInfo.prt > 0;
    },
    daysBeforeNewPrice() {
      if (!this.raceInfo.ph) {
        return null;
      }
      const days = Math.floor((new Date(this.raceInfo.ph) - new Date()) / 1000 / 60 / 60 / 24);
      return days > 0 ? days : null;
    },
  },
  methods: {
    getRegistrationAvailable(registration) {
      return registration.active && !registration.disabled;
    },
    setData() {
      if (!this.event) {
        return;
      }
      this.event = this.eventInfo || {};
      this.eventCode = this.event.c;
      this.name = this.event.t;
      this.location = this.event.p;

      if (this.results) {
        this.races = this.event.ri;
      } else {
        this.races = (this.event.ri && this.event.ri.length)
          ? filter(this.event.ri, (o) => !o.dr)
          : null;
      }

      const {
        rrr, sportmaster, nrm, sr, asr, hw, har, pra, pr,
      } = this.event;

      this.badges = {
        rrr,
        // sportmaster,
        nrm,
        sr: !hw && sr,
        asr: !har && asr,
        pra: pra && Math.round(+pra.replace(',', '.') * 10) / 10,
        praPopup: pr,
        empty: false,
      };

      this.badges.empty = !rrr && !sportmaster && !nrm
        && !this.badges.sr && !this.badges.asr && !this.badges.pra;

      this.setImage('image', getCroppedImg(this.event.ImageUrl, [null, 400]));
      if (this.event.MobileImageUrl) {
        this.setImage('mobileImage', getCroppedImg(this.event.MobileImageUrl, [null, 400]));
      }

      this.setRaces();
      this.setEventRegistration();
    },
    setRaces() {
      const races = [];
      const racesInfo = {};
      this.race = null;

      if (!this.races) {
        this.racesOptions = races;
        this.racesInfo = racesInfo;
        return;
      }

      this.setRacesRegistrationInfo();

      for (let i = 0, l = this.races.length; i < l; i += 1) {
        const race = this.races[i];

        races.push({
          id: race.id,
          name: race.n,
        });

        racesInfo[race.id] = race;
      }

      this.racesOptions = races;
      this.racesInfo = racesInfo;

      if (this.racesOptions.length) {
        this.setDefaultRace();
      }
    },
    setRacesRegistrationInfo() {
      let { races } = this;

      races = races.map((race) => {
        const registration = {
          notOpened: race.rs === 1,
          active: race.rs === 2,
          closed: race.rs === 3 || (race.rcd && race.rcd < new Date()),
          disabled: race.dr,
          available: race.rs === 2 && !race.dr,
          offlineAvailable: race.eor,
          count: race.rc,
          noPlaces: race.rc === 0,
          fewPlaces: false,
        };

        if (typeof race.rc === 'number' && typeof race.pc === 'number') {
          const total = race.rc + race.pc;
          const availablePercent = (race.rc / total) * 100;
          registration.fewPlaces = availablePercent ? availablePercent < 10 : false;
        }

        return {
          ...race,
          registration,
        };
      });

      this.races = races;
    },
    setEventRegistration() {
      const races = this.races || [];
      const eventRegistration = {
        noPlaces: true,
        fewPlaces: true,
      };

      if (!races.length) {
        return;
      }

      races.some((race) => {
        const { registration } = race;
        const noPlaces = !registration.notOpened && (!registration.available || registration.noPlaces);

        if (registration.offlineAvailable || !noPlaces) {
          eventRegistration.noPlaces = false;
        }
        if (registration.offlineAvailable || !registration.fewPlaces) {
          eventRegistration.fewPlaces = false;
        }

        // Stop loop when values = false
        return !eventRegistration.noPlaces && !eventRegistration.fewPlaces;
      });

      this.eventRegistration = eventRegistration;
    },
    setDefaultRace() {
      let defaultRace = null;

      if (!this.results) {
        // Race with available registration
        defaultRace = this.races.find(
          (race) => race.is_default_selected && race.rc !== 0 && this.getRegistrationAvailable(race.registration),
        );
        defaultRace = defaultRace
          || this.races.find((race) => (race.rc !== 0 && this.getRegistrationAvailable(race.registration)))
          || this.races[0];
      } else {
        // Race with max participants count
        defaultRace = this.races.reduce((prev, current) => ((prev.pc > current.pc) ? prev : current));
      }

      this.race = defaultRace?.id;
      this.onRaceChange();
    },
    onRaceChange() {
      this.registration = this.raceInfo.registration || {};
    },
    setImage(key, imageUrl) {
      const url = imageUrl;
      const img = new Image();
      const d = this.event.dc;

      const { sportTypesImages } = this;

      let imgUrl = sportTypesImages.run.img;
      if (sportTypesImages[d] && sportTypesImages[d].img) {
        imgUrl = sportTypesImages[d].img;
      }

      if (url) {
        img.onload = () => {
          this[key] = url;
        };
        img.onerror = () => {
          this[key] = `${process.env.VUE_APP_URL}${imgUrl}`;
        };
        img.src = url;
      } else {
        const defaultImg = `${process.env.VUE_APP_URL}${imgUrl}`;
        this[key] = defaultImg;
        img.src = defaultImg;
      }
    },
  },
  mounted() {
    this.setData();
  },
};
</script>

<style lang="scss">
  .event-card {
    display: flex;
    @include base-sm-shadow;
    transition: $transition-default box-shadow;

    .no-touch &:hover {
      box-shadow: $elevation-deth-8;
    }

    &__container {
      width: 100%;
      min-height: 200px;

      @media (min-width: breakpoint(sm)) {
        display: flex;
      }
    }

    &__cover {
      flex: 0 0 auto;
      height: 176px;
      background-size: cover;
      background-position: center;
      position: relative;
      border-radius: 4px 4px 0 0;
      background-color: $color-bg-light;

      @media (min-width: breakpoint(sm)) {
        width: 200px;
        height: auto;
        border-radius: 4px 0 0 4px;
      }
    }

    &__cover-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      border-radius: inherit;
      animation: fadein 0.15s;
    }

    &__cover-link {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    &__badges {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      background: linear-gradient(
          180deg,
          $color-black-45 0%,
          $color-black-85 100%);
      border-radius: 0 0 4px 4px;
      color: $color-white;
      width: 60px;
      padding: 8px 0;
      position: absolute;
      top: 0;
      right: 8px;

      @media (min-width: breakpoint(sm)) {
        flex-direction: row;
        top: auto;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 56px;
        padding: 0 12px;
        border-bottom-right-radius: 0;
      }
    }

    &__badges-item {
      flex: 0 0 auto;
      margin-top: 8px;
      line-height: 1;

      &:first-child {
        margin-top: 0;
      }

      img {
        display: block;
        height: 24px;
        width: auto;
      }

      @media (min-width: breakpoint(sm)) {
        margin-top: 0;
        margin-left: 8px;

        &:first-child {
          margin-left: 0;
        }

      }
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.25rem;
    }

    &__header-col {
      flex: 0 0 auto;
      max-width: 100%;
    }

    &__info {
      flex: 1 1 100%;
      min-width: 0;
      display: flex;
      flex-direction: column;
      padding: 12px 16px 16px;

      @media (min-width: breakpoint(md)) {
        padding: 16px 20px;
      }
    }

    &__name {
      display: block;
      @include text-lg;
      font-weight: $font-weight-semibold;
      color: inherit;
      text-decoration: none;
      transition: $transition-default;
      max-height: 78px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      @media (min-width: breakpoint(sm)) {
        max-height: 52px;
        -webkit-line-clamp: 2;
      }

      .no-touch &:hover {
        color: $color-primary;
      }
    }

    &__body-item {
      margin-bottom: 1rem;
    }

    &__footer {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      margin: 0 -8px -16px;
      margin-top: auto;

      @media (min-width: breakpoint(sm)) {
        display: block;
      }
    }

    &__footer-item {
      flex: 0 0 auto;
      max-width: 100%;
      min-width: 0;
      padding: 0 8px;
      margin-bottom: 16px;

      &_main {
        flex: 1 1 100%;
      }
    }

    &__location {
      @include text-sm;
      white-space: nowrap;
      min-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1 1 auto;
    }

    &__actions {
      display: none;
      margin-top: -16px;
      width: 245px + 32px;
      padding: 16px;
      text-align: center;

      @media (min-width: breakpoint(xl)) {
        display: flex;
        flex: 0 0 auto;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    &__actions-item {
      margin-top: 16px;
      line-height: 0;
    }

    &__cost {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-primary;

      > * {
        vertical-align: middle;
      }

      &_default {
        color: inherit;
      }

      &_muted {
        color: $color-black-25;
      }
    }

    &__cost-tooltip {
      flex: 0 0 auto;
      margin-left: 8px;
    }

    &__status {
      @include text-xs;
      margin-top: 0.25rem;
    }
  }

  .stars {
    font-size: 12px;
    font-weight: $font-weight-semibold;
    cursor: default;
    user-select: none;
    width: 28px;
    height: 28px;
    fill: $color-bg-light;
    color: $color-black;
    position: relative;

    &_approved {
      fill: #EE1D23;
      color: $color-white;
    }

    &__text {
      position: absolute;
      line-height: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    svg {
      fill: inherit;
    }
  }

  .member-rating-badge {
    background-color: #518dcb;
  }

  .member-rating-popup {
    max-width: calc(100vw - 10px);

    @media (min-width: breakpoint(sm)) {
      max-width: 480px;
    }

    table td {
      padding: 6px 0 6px 16px;
      vertical-align: middle;

      &:first-child {
        padding-left: 0;
      }
    }

    table tr {
      border-top: 1px solid $color-white-45;

      &:first-child {
        border-top: 0;
      }
    }
  }
</style>
