<template>
  <div
    class="multiselect-container"
    :class="{'multiselect-container_transparent': type !== 'base'}"
  >
    <multi-select
      v-model="val"
      :options="options"
      :class="classes"
      :track-by="trackBy"
      :label="label"
      :multiple="multiple"
      :placeholder="placeholder"
      :disabled="disabled"
      :loading="loading"
      :searchable="searchable"
      :allow-empty="clearable"
      :limit="limit"
      :close-on-select="!multiple"
      :openDirection="openDirection"
      :group-select="groupSelect"
      :group-values="groupSelect ? groupValues : null"
      :group-label="groupSelect ? groupLabel : null"
      @select="onSelect"
      @remove="onRemove"
      @search-change="searchChange"
    >
      <template slot="caret" slot-scope="{ toggle }">
      <div class="multiselect__area" @mousedown.prevent.stop="toggle"></div>
      <div class="multiselect__select">
        <div
          class="multiselect__select-icon multiselect__select-icon_danger"
          v-show="hasError"
        >
          <icon icon-name="exclamation-circle-s"></icon>
        </div>
        <div
          class="multiselect__select-icon multiselect__select-icon_warning"
          v-show="hasWarning"
        >
          <icon icon-name="warning-s"></icon>
        </div>
        <div
          class="multiselect__select-icon multiselect__select-icon_success"
          v-show="hasSuccess"
        >
          <icon icon-name="check-bold"></icon>
        </div>
        <div
          class="multiselect__select-icon multiselect__caret"
          v-show="!showClearBtn"
          @mousedown.prevent.stop="toggle"
        >
          <icon icon-name="down"></icon>
        </div>
        <div
          class="multiselect__select-icon multiselect__clear"
          v-show="showClearBtn"
          @mousedown.prevent.stop="clearSelect"
        >
          <icon icon-name="close-circle-s"></icon>
        </div>
      </div>
      </template>

      <template v-if="!$slots.noSearchResult">
        <span
          class="multiselect__info"
          slot="noResult"
        >
          {{ $t('noResults') }}
        </span>
        <span
          class="multiselect__info"
          slot="noOptions"
        >{{ $t('loading') }}</span>
      </template>
      <template v-else>
        <span
          class="multiselect__info"
          slot="noResult"
        >
          <slot name="noSearchResult"></slot>
        </span>
        <span
          class="multiselect__info"
          slot="noOptions"
        >
          <slot name="noSearchResult"></slot>
        </span>
      </template>

      <template
        slot="singleLabel"
        slot-scope="{ option }"
      >
        <icon
          class="icon"
          v-if="iconName"
          :icon-name="iconName"
        />
        <span>{{ option[label] }}</span>
      </template>
      <template
        v-if="multiple && limit !== Infinity"
        slot="selection"
        slot-scope="{ values }"
      >
        <span
          class="multiselect__single"
          v-if="values.length"
        >{{ limitText }}</span>
      </template>
      <template
        v-if="multiple"
        slot="option"
        slot-scope="props"
      >
        <div class="s-option">
          <div class="s-option__check">
            <icon icon-name="check-bold"></icon>
          </div>
          <div class="s-option__text">{{ props.option[label] }}</div>
        </div>
      </template>
    </multi-select>
  </div>
</template>

<script>
import MultiSelect from 'vue-multiselect';
import Icon from './Icon.vue';

export default {
  name: 'BaseSelect',
  components: {
    MultiSelect,
    Icon,
  },
  props: {
    value: [String, Array],
    options: Array,
    placeholder: {
      type: String,
      default: 'Выберите опцию',
    },
    type: {
      type: String,
      default: 'base',
      validator(value) {
        return ['base', 'simple'].indexOf(value) !== -1;
      },
    },
    size: String,
    trackBy: {
      type: String,
      default: 'id',
    },
    label: {
      type: String,
      default: 'name',
    },
    hasError: Boolean,
    hasWarning: Boolean,
    hasSuccess: Boolean,
    multiple: Boolean,
    disabled: Boolean,
    loading: Boolean,
    searchable: Boolean,
    clearable: Boolean,
    openDirection: String,
    limit: {
      type: Number,
      default: 1,
    },
    groupSelect: Boolean,
    groupLabel: {
      type: String,
      default: 'group',
    },
    groupValues: {
      type: String,
      default: 'items',
    },
    cls: String,
    iconName: String,
  },
  data() {
    return {
      val: null,
    };
  },
  computed: {
    limitText() {
      if (!this.val) {
        return null;
      }
      return this.val.length > this.limit ? `Выбрано • ${this.val.length}` : this.val[0][this.label];
    },
    showClearBtn() {
      return this.clearable && this.value && this.value.length;
    },
    classes() {
      let classes = `${this.cls}`;
      classes += ` multiselect_${this.type}`;
      if (this.size) {
        classes += ` multiselect_${this.size}`;
      }
      if (this.hasError) {
        classes += ' multiselect_danger';
      }
      if (this.hasWarning) {
        classes += ' multiselect_warning';
      }
      if (this.loading) {
        classes += ' multiselect_loading';
      }
      return classes;
    },
  },
  methods: {
    updateValue() {
      this.$nextTick(() => {
        const value = this.getValue();
        this.savedValue = value;
        this.$emit('input', value);
      });
    },
    getValue() {
      const value = this.val;

      if (!value) {
        return null;
      }

      // Single
      if (!this.multiple) {
        return value[this.trackBy] || null;
      }

      // Multiple
      return value.map((currentValue) => currentValue[this.trackBy]);
    },
    prepareValues() {
      let { value } = this;

      // Single
      if (!this.multiple) {
        if (Array.isArray(value)) {
          [value] = value;
        }
        this.val = this.options.find((o) => o[this.trackBy] === value);
        return;
      }

      // Multiple
      const options = [];
      value = value || [];
      if (!Array.isArray(value)) {
        value = [value];
      }
      for (let i = 0; i < value.length; i += 1) {
        options.push(this.options.find((o) => o[this.trackBy] === value[i]));
      }
      this.val = options;
    },
    clearSelect() {
      this.val = null;
      this.updateValue();
    },
    onSelect() {
      this.updateValue();
    },
    onRemove() {
      this.updateValue();
    },
    searchChange(query) {
      this.$emit('search', query);
    },
  },
  watch: {
    value: {
      handler(val) {
        if (val !== this.savedValue) {
          this.prepareValues();
        }
      },
      immediate: true,
    },
    options(val) {
      if (val && val.length) {
        this.prepareValues();
      }
    },
  },
};
</script>

<style lang="scss">
  @import "~vue-multiselect/dist/vue-multiselect.min.css";

  .multiselect-container {
    background-color: $color-white;
    cursor: not-allowed;

    &_transparent {
      background: none;
    }
  }

  .multiselect {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    min-height: 0;
    height: 40px;
    color: inherit;
    cursor: pointer;
    transition: $transition-default;
    transition-property: border, box-shadow;

    &_md {
      height: 24px;
    }

    &_sm {
      height: 22px;
    }

    &_lg {
      height: 48px;
    }

    &_white {
      background: $color-white;
      border-radius: 4px;
      box-shadow: $elevation-deth-4;
    }

    &_base {
      background: $color-white;
      border-radius: $border-radius-base;
      border: 1px solid $color-black-45;

      &.multiselect--active {
        border-color: $color-black-65;
        box-shadow: 0 0 0 1px $color-black-65;
      }

      &.multiselect_danger {
        border-color: $color-danger;

        &.multiselect--active {
          box-shadow: 0 0 0 1px $color-danger;
        }
      }

      &.multiselect_warning {
        border-color: $color-warning;

        &.multiselect--active {
          box-shadow: 0 0 0 1px $color-warning;
        }
      }

      &.multiselect--disabled {
        color: $color-black;
        background-color: $color-black-4;
        border-color: $color-black-25;
        opacity: 1;
      }
    }

    &__area {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &__select {
      font-size: 0;
      position: relative;
      flex: 0 0 auto;
      order: 1;
      width: auto;
      padding: 0 6px 0 0;
      line-height: inherit;
      top: 0;
      right: 0;
      cursor: auto;
      background: none !important;
      height: auto;
      pointer-events: none;

      .multiselect--active & {
        transform: none;
      }

      .multiselect_loading & {
        opacity: 0;
      }

      .multiselect_simple & {
        padding: 0;
        margin-right: -6px;
      }

      &::before {
        content: none;
      }
    }

    &__select-icon {
      display: inline-block;
      vertical-align: middle;
      padding: 6px;
      line-height: 1;

      &_success {
        color: $color-success;
      }

      &_warning {
        color: $color-warning;
      }

      &_danger {
        color: $color-danger;
      }

      .svg-icon {
        display: block;
        width: 18px;
        height: 18px;
      }

      .multiselect_sm & {
        padding: 4px;

        .svg-icon {
          width: 14px;
          height: 14px;
        }
      }
    }

    &__caret, &__clear {
      cursor: pointer;
      opacity: 0.45;
      transition: $transition-default opacity;
      pointer-events: auto;

      &:hover {
        opacity: 0.65;
      }
    }

    &__caret {

      .multiselect--active & {
        transform: rotate(180deg);
      }
    }

    &__spinner {
      width: 18px;
      height: 18px;
      top: 50%;
      right: 12px;
      margin-top: -9px;
      background-image: url("../assets/img/icons/loader.svg");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-color: transparent;
      transition: none;

      &::before, &::after {
        content: none;
      }

      .multiselect_loading & {
        animation: loading 1s linear infinite;
      }

      .multiselect_simple & {
        right: 6px;
      }
    }

    &__tags {
      flex: 1 1 100%;
      min-width: 0;
      font-size: 16px;
      border-radius: $border-radius-base;
      padding: 0 6px 0 16px;
      min-height: 0;
      line-height: 1;
      background: none;
      border: none;

      .multiselect_sm & {
        font-size: 14px;
      }

      .multiselect_simple & {
        padding-left: 0;
        padding-right: 0;
      }

      .multiselect_white & {
        font-weight: $font-weight-semibold;
      }
    }

    &__input, &__single, &__placeholder {
      padding: 0;
      margin: 0;
      vertical-align: middle;
      background: none;
    }

    &__single {
      font-size: inherit;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
      pointer-events: none;

      .icon {
        flex: 0 0 auto;
        display: block;
        color: $color-black-45;
        width: 18px;
        height: 18px;
        margin-right: 12px;
      }

      > span {
        flex: 1 1 auto;
        min-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .multiselect_white & .icon {
        color: inherit;
      }
    }

    &__single-text {
      flex: 0 1 auto;
      min-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__single-badge {
      flex: 0 0 auto;
      line-height: 0;
      margin-left: 8px;
    }

    &__input {
      color: inherit;

      &::placeholder {
        color: $color-black-25;
      }
    }

    &__placeholder {
      color: $color-black-25;
      min-width: 100%;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;

      input ~ & {
        cursor: text;
      }
    }

    &__content-wrapper {
      color: $color-black;
      line-height: 1;
      border-color: $color-black-4;
      box-shadow: $elevation-deth-8;
      border-radius: 0 0 4px 4px;
      padding: 4px 0;
      top: 100%;
      left: 0;
      margin: 2px 0;
      min-width: 100%;
      overflow-x: hidden;

      .multiselect--above & {
        top: auto;
      }

      .multiselect_dropdown-auto & {
        width: auto;
        max-width: 360px;
      }
    }

    &__element &__option {
      font-size: inherit;
      font-weight: $font-weight-semibold;
      padding: 8px 15px;
    }

    &__option {
      min-height: 0;
      padding: 4px 16px;
      border-radius: 4px;
      line-height: 1.5em;

      .multiselect_wrap & {
        white-space: normal;
      }

      &::after {
        display: none;
      }

      &--highlight {
        background-color: $color-black-4;
        color: inherit;
      }

      &--selected {
        color: $color-primary;

        &, &.multiselect__option--highlight {
          background-color: $color-primary-1;
          color: $color-primary;
        }
      }
    }

    &__info {
      font-size: 12px;
      color: $color-black-65;
    }
  }

  .s-option {
    display: flex;
    align-items: center;

    &__icon,
    &__check {
      display: block;
      flex: 0 0 auto;
      width: 18px;
      height: 18px;
      margin-right: 12px;
    }

    &__check {
      border: 1px solid $color-black-45;
      background-color: $color-white;
      border-radius: $border-radius-base;
      position: relative;
      color: $color-white;

      .multiselect__option--highlight & {
        border-color: $color-primary;
      }

      .multiselect__option--selected & {
        background-color: $color-primary;
        border-color: transparent;

        svg {
          opacity: 1;
        }
      }

      svg {
        width: 12px;
        height: 12px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
      }
    }

    &__badge {
      flex: 0 0 auto;
      margin-left: 8px;
    }
  }

  @keyframes loading {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
