<template>
  <div
    class="events-search"
    :class="{
      'events-search_active': this.isActive,
      'events-search_empty': this.dropdownIsEmpty,
    }">
    <div class="events-search__overlay overlay" @click="closeSearch"></div>
    <div class="events-search__container">
      <div class="input" @click="openSearch(true)">
        <div class="input__icons">
          <div class="input__icon">
            <icon icon-name="search"></icon>
          </div>
        </div>
        <input
          v-model.trim="search"
          class="events-search__input input__el"
          type="search"
          :placeholder="$t('events.search.placeholder') + '...'"
          name="search"
          autocomplete="off"
          ref="input"
          @focus="openSearch(false)"
        >
        <div class="input__icons d-none d-md-block">
          <button
            v-show="search"
            @click="setSearch(null)"
            class="input__icon input__icon_hover"
          >
            <icon icon-name="close-circle-s"></icon>
          </button>
        </div>
      </div>
      <Dropdown
        trigger="input"
        :noListeners="true"
        placement="bottom-start"
        :usePopper="false"
        :hideOverlay="true"
        :strategy="null"
        ref="searchDropdown"
      >
        <template slot="items">
          <div class="events-search__header d-md-none">
            <div class="input">
              <div class="input__icons">
                <div
                  class="input__icon color-black-85"
                  @click="() => { setSearch(null); closeSearch(); }"
                >
                  <icon icon-name="left"></icon>
                </div>
              </div>
              <input
                v-model.trim="search"
                class="input__el"
                type="search"
                :placeholder="$t('events.search.placeholder') + '...'"
                name="search"
                autocomplete="off"
                ref="mobileInput"
              >
              <div class="input__icons">
                <button
                  v-show="search"
                  @click="setSearch(null)"
                  class="input__icon input__icon_hover"
                >
                  <icon icon-name="close-circle-s"></icon>
                </button>
              </div>
            </div>
          </div>
          <div
            class="events-search__subheader base-dropdown__item bg-primary-1"
            v-if="!resultsPage"
            v-show="searchLength"
          >
            <div class="base-dropdown__item">
              <div class="base-dropdown__link base-dropdown__link_no-hover">
                <div class="base-dropdown__text">
                  <div class="base-dropdown__text-col base-dropdown__text-col_main">
                    {{ allSeasons
                      ? $t('events.search.allTime')
                      : $t('events.search.currentSeason')
                    }}
                  </div>
                  <div class="base-dropdown__text-col">
                    <label class="switch">
                    <span class="switch__content">
                      <span class="switch__text">{{ $t('events.search.allSeasons') }}</span>
                      <input
                        v-model="allSeasons"
                        class="switch__input"
                        type="checkbox"
                        name="allSeasons"
                      >
                      <span class="switch__marker">
                        <icon icon-name="check-bold"></icon>
                        <icon icon-name="close"></icon>
                      </span>
                    </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="base-dropdown__section" v-show="isSearching">
            <div class="base-dropdown__group">
              <div class="loader m-0"></div>
            </div>
          </div>
          <div class="base-dropdown__section" v-show="searchLength && !isSearching && notFound">
            <div class="base-dropdown__group">
              <div class="base-dropdown__link base-dropdown__link_no-hover pt-0 pb-0">
                <div class="base-dropdown__text">
                  <div class="base-dropdown__text-col base-dropdown__text-col_main">
                    <b>{{ $t('events.search.noResultsTitle') }}</b>
                    <div class="base-dropdown__details">{{ $t('events.search.noResultsText') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="base-dropdown__section mt-0" v-show="showResults">
            <div class="base-dropdown__group">
              <div
                class="base-dropdown__item"
                v-for="item in results"
                :key="item.Code"
              >
                <router-link
                  class="base-dropdown__link"
                  :to="{path: item.DetailesPageRelativeUrl, query: {search_ce: 1}}"
                >
                  <div class="base-dropdown__text">
                    <div class="base-dropdown__text-col base-dropdown__text-col_main">
                      <b>{{ item.Title }}</b>
                      <div class="base-dropdown__details">
                        <div class="base-dropdown__details-row">
                          <div class="base-dropdown__details-item">
                            {{ $moment(item.BeginDate).format('DD MMMM YYYY[, ]HH:mm') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="base-dropdown__text-col">
                      <badge
                        class="v-align-top"
                        v-if="item.fewPlaces"
                        text="Мало мест"
                        type="warning"
                        icon="fire"
                      >
                      </badge>
                      <badge
                        class="v-align-top"
                        v-else-if="item.noPlaces"
                        text="Нет мест"
                        type="muted"
                      >
                      </badge>
                      <icon v-else class="base-dropdown__text-icon" icon-name="right"></icon>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
          <div class="base-dropdown__section" v-show="showPopular">
            <div class="base-dropdown__paddings">
              <div class="base-dropdown__heading">
                <div class="heading-line text-md">
                  <span class="heading-line__text">Чаще всего ищут</span>
                </div>
              </div>
            </div>
            <div
              class="base-dropdown__item"
              v-for="item in popular"
              :key="'popular-' + item.id"
            >
              <div
                class="base-dropdown__link"
                @click="setSearch(item.name)"
              >
                <icon
                  class="base-dropdown__icon"
                  icon-name="fire"
                ></icon>
                <div class="base-dropdown__text">
                  <div class="base-dropdown__text-col base-dropdown__text-col_main">
                    {{ item.name }}
                  </div>
                  <div class="base-dropdown__text-col">
                    <icon class="base-dropdown__text-icon" icon-name="query-arrow"></icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="base-dropdown__section" v-show="recently.length && !searchLength">
            <div class="base-dropdown__paddings">
              <div class="base-dropdown__heading">
                <div class="heading-line text-md">
                  <span class="heading-line__text">{{ $t('events.search.recentlySearched') }}</span>
                </div>
              </div>
            </div>
            <div
              class="base-dropdown__item"
              v-for="item in recently"
              :key="item"
            >
              <div
                class="base-dropdown__link"
                @click="setSearch(item)"
              >
                <icon
                  class="base-dropdown__icon"
                  icon-name="clock"
                ></icon>
                <div class="base-dropdown__text">
                  <div class="base-dropdown__text-col base-dropdown__text-col_main">{{ item }}</div>
                  <div class="base-dropdown__text-col">
                    <icon class="base-dropdown__text-icon" icon-name="query-arrow"></icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </Dropdown>
    </div>
  </div>
</template>

<script>
import Badge from '@/components/Badge.vue';
import eventsService from '@/services/eventsService';
import { debounce, orderBy } from 'lodash';

export default {
  name: 'EventsSearch',
  components: {
    Badge,
  },
  props: {
    resultsPage: Boolean,
  },
  data() {
    return {
      env: process.env,
      isMounted: false,
      isActive: false,
      isSearching: false,
      search: null,
      allSeasons: false,
      results: [],
      popular: [],
      recently: [],
    };
  },
  computed: {
    searchLength() {
      return this.search && this.search.length > 2;
    },
    showResults() {
      return this.searchLength && !this.isSearching && !this.notFound;
    },
    showPopular() {
      return this.popular.length && (!this.searchLength || this.notFound);
    },
    notFound() {
      return this.search && this.results.length < 1;
    },
    dropdownIsEmpty() {
      return !this.searchLength
        && !this.isSearching
        && !this.popular.length
        && !this.recently.length;
    },
  },
  methods: {
    openSearch(mobile) {
      if (this.isActive) {
        return;
      }

      if (window.innerWidth < 768 && mobile) {
        document.querySelector('html').style.overflow = 'hidden';
        document.body.style.overflow = 'hidden';
        this.$refs.searchDropdown.$el.querySelector('.base-dropdown__menu').scrollTo(0, 0);
        this.$refs.searchDropdown.open();
        this.$refs.mobileInput.focus();
        this.isActive = true;
      }
      if (!mobile) {
        this.isActive = true;
        this.timeout = setTimeout(() => {
          this.$refs.searchDropdown.open();
        }, 200);
      }

      if (window.ym) {
        window.ym(42140674, 'reachGoal', 'search_event');
      }
    },
    closeSearch() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.$refs.searchDropdown.close();
      this.isActive = false;
      document.querySelector('html').style.overflow = null;
      document.body.style.overflow = null;
    },
    setSearch(val) {
      this.search = val;
      if (this.isActive && window.innerWidth > 767) {
        this.$refs.input.focus();
      }
      if (val === null) {
        this.clear();
      }
    },
    updateHistory(query) {
      let history = this.recently;
      const index = history.indexOf(query);
      if (index !== -1) {
        history.splice(index, 1);
      }
      history.unshift(query);
      history = history.slice(0, 4);
      localStorage.setItem('eventsSearchHistory', JSON.stringify(history));
      this.recently = history;
    },
    getSearchHistory() {
      const history = localStorage.getItem('eventsSearchHistory');
      this.recently = history ? JSON.parse(history) : [];
    },
    onChange() {
      if (!this.search || this.search.length < 3
        || (this.prevSearch === this.search && this.prevSeason === this.allSeasons)) {
        this.isSearching = false;
        return;
      }
      this.prevSearch = this.search;
      this.prevSeason = this.allSeasons;
      eventsService.cancelSearchEvents();
      this.searchEvents();
      this.results = [];
    },
    searchEvents() {
      const searchQuery = this.search;
      const { allSeasons } = this;
      this.isSearching = true;

      eventsService.searchEvents({
        title: searchQuery,
        currentSeason: this.resultsPage ? false : !allSeasons,
        showPast: !!this.resultsPage,
      })
        .then((res) => {
          let results = res.data || [];
          results = orderBy(results, (o) => new Date(o.BeginDate), 'desc');
          this.results = results;
          this.updateHistory(searchQuery);
          this.isSearching = false;
        });
    },
    clear() {
      this.isSearching = false;
    },
  },
  watch: {
    search(val) {
      this.isSearching = val && val.length > 2;
      this.debouncedSearch();
    },
    allSeasons() {
      this.debouncedSearch();
    },
  },
  mounted() {
    this.isMounted = true;
  },
  created() {
    this.debouncedSearch = debounce(this.onChange, 1000);
    this.getSearchHistory();
  },
  beforeDestroy() {
    if (this.isActive) {
      this.closeSearch();
    }
  },
};
</script>

<style lang="scss">
  .events-search {
    width: 100%;
    margin-left: auto;
    position: relative;
    transition: $transition-default max-width;
    transition-duration: 0.1s;
    height: 40px;

    @media (min-width: breakpoint(md)) {
      max-width: 437px;
      margin-top: 0;

      &_sm {
        max-width: 285px;
      }
    }

    &_active {
      max-width: 100%;
      z-index: 1005;
    }

    &_empty {

      .base-dropdown__block {

        @media (min-width: breakpoint(md)) {
          display: none;
        }
      }
    }

    &__header {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 10;
      padding: 8px 0;
      background-color: $color-white;
    }

    &__subheader {
      position: sticky;
      top: 0;
      z-index: 1;

      &::before {
        content: "";
        position: absolute;
        bottom: 100%;
        left: 0;
        height: 10px;
        width: 100%;
        background-color: $color-white;
      }
    }

    &__input {

      @media (max-width: breakpoint(md, max)) {
        pointer-events: none;
      }
    }

    &__overlay {
      position: fixed;
      pointer-events: none;
      opacity: 0;

      .events-search_active & {
        transition: $transition-default;
        transition-duration: 0.2s;

        @media (min-width: breakpoint(md)) {
          pointer-events: auto;
          opacity: 1;
        }
      }
    }

    &__container {
      position: relative;
      @include base-sm-shadow;
      border-radius: $border-radius-base;
    }

    .base-dropdown {
      position: relative;
      width: 100%;
      max-height: 0;
      z-index: 2;
      visibility: visible;
      overflow: hidden;

      @media (max-width: breakpoint(md, max)) {
        position: fixed;
        top: 0 !important;
        left: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;
      }

      &.active {
        max-height: none;
        transition-duration: 0s;
      }

      &__block {
        padding: 0 !important;

        @media (max-width: breakpoint(md, max)) {
          height: 100%;
        }
      }

      &__container {
        border-radius: 0 0 $border-radius-base $border-radius-base;
        border-top: 0;
        box-shadow: none;

        @media (max-width: breakpoint(md, max)) {
          height: 100%;
        }
      }

      &__menu {
        max-height: 470px;
        padding-top: 4px;

        @media (max-width: breakpoint(md, max)) {
          max-height: 100%;
          padding-top: 64px;
        }
      }

      &__link {
        white-space: normal;
        font-weight: normal;
      }

      &__icon {
        color: $color-black-45;
      }
    }
  }
</style>
