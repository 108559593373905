<template>
  <div class="layout__header">
    <template v-if="theme === 'rr'">
      <RRHeader activeMenu="calendar"></RRHeader>
      <div class="rr-header-offset"></div>
    </template>
    <component
      v-else
      :is="currentTenantHeader"
    >
    </component>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RRHeader from '@/components/rr/Header/Header.vue';

export default {
  name: 'TenantHeader',
  components: {
    RRHeader,
  },
  computed: {
    ...mapGetters('settings', ['theme']),
    currentTenantHeader() {
      const tenants = {
        topliga2: () => import('@/components/topliga2/Header/Header.vue'),
        bfla: () => import('@/components/bfla/Header/Header.vue'),
        steelcharacter: () => import('@/components/steelcharacter/Header/Header.vue'),
        samarkand: () => import('@/components/samarkand/Header/Header.vue'),
        rzd: () => import('@/components/rzd/Header/Header.vue'),
        pionercup: () => import('@/components/pionercup/Header/Header.vue'),
        legkovrace: () => import('@/components/legkovrace/Header/Header.vue'),
        tula: () => import('@/components/tula/Header/Header.vue'),
      };

      return tenants[this.theme];
    },
  },
};
</script>
