import eventsService from '@/services/eventsService';
import { sortBy } from 'lodash';

const state = {
  filters: null,
  weather: {},
};

const mutations = {
  // eslint-disable-next-line no-shadow
  saveFilters(state, filters) {
    const result = filters;
    const d = [];

    for (let i = 0; i < filters.d.length; i += 1) {
      const item = filters.d[i];
      if (item.Name) {
        d.push(item);
      }
    }

    result.d = sortBy(d, (o) => o.Name.toLowerCase());
    state.filters = result;
  },
  // eslint-disable-next-line no-shadow
  saveWeather(state, { key, value }) {
    const obj = {};
    obj[key] = value;
    state.weather = { ...state.weather, ...obj };
  },
};

const getters = {};

const actions = {
  getFilters({ commit }) {
    eventsService.getFilters()
      .then((res) => {
        commit('saveFilters', res.data);
      });
  },
  // eslint-disable-next-line no-shadow
  getWeather({ state, commit }, {
    temperatureType,
    latitude,
    longitude,
    d,
    day,
  }) {
    if (!latitude || !longitude) {
      return;
    }

    const key = `${latitude}-${longitude}-${d}`;
    if (state.weather[key]) {
      return;
    }

    eventsService.getWeather({
      temperatureType,
      latitude,
      longitude,
      day,
    })
      .then((res) => {
        commit('saveWeather', { key, value: res.data });
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
