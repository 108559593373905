<template lang="pug">
  .alert(:class="['alert_' + type, cls]")
    .alert__container
      .alert__col.alert__col_main
        .alert__icon(
          v-if="iconName"
          :class="{'alert__icon_sm' : !title}"
        )
          icon(:icon-name='iconName')
        .alert__content
          .alert__title(v-if="title" v-html="title")
          .alert__text(v-if="text" v-html="text")
          slot(name="text")
      .alert__col(
        v-if="button || $slots.buttons"
        :class="buttonsCls"
      )
        .alert__buttons
          .alert__button(v-if="button")
            button.action
              .action__container
                span.action__icon
                  icon(icon-name='check-bold')
                span.action__text(v-if="button.text") {{ button.text }}
          slot(name="buttons")
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Alert',
  data() {
    return {
      icons: {
        success: ['check-circle-s', 'check-circle'],
        warning: ['warning-s', 'warning'],
        danger: ['exclamation-circle-s', 'exclamation-circle'],
      },
      timeout: null,
    };
  },
  props: {
    type: {
      type: String,
      default: 'success',
    },
    icon: String,
    noIcon: Boolean,
    title: String,
    text: String,
    button: [Object, Boolean],
    buttonsCls: [String, Array],
    cls: [String, Array],
    isAlert: Boolean,
    id: Number,
    pos: String,
  },
  computed: {
    iconName() {
      if (this.noIcon) {
        return false;
      }
      if (this.icon) {
        return this.icon;
      }
      const iconSize = this.title ? 1 : 0;
      return this.icons[this.type][iconSize];
    },
  },
  methods: {
    ...mapActions('alerts', ['removeAlert']),
    close() {
      clearTimeout(this.timeout);
      this.removeAlert(this.id);
    },
  },
  mounted() {
    if (!this.isAlert) {
      return;
    }
    const vm = this;
    const duration = 7000;
    vm.$el.addEventListener('click', vm.close);
    this.timeout = setTimeout(vm.close, duration);
  },
};
</script>
