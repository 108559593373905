<template>
  <div
    class="banner-block"
    v-if="htmlContent"
    v-html="htmlContent"
  >
  </div>
</template>

<script>
export default {
  name: 'Banner',
  props: {
    content: String,
    contentMobile: String,
  },
  data() {
    return {
      isMobile: false,
      htmlContent: null,
    };
  },
  methods: {
    putContent() {
      let el = document.createElement('div');
      el.innerHTML = this.getContent();
      el = el.querySelector('.ad-pic');

      const baseUrl = el.getAttribute('data-base-url');
      const device = el.getAttribute('data-device');
      const url = `${baseUrl}/${this.createUUID()}_${device}`;

      this.htmlContent = `<div style="width: 100%; text-align: center; display: block;"><a href="${
        url
      }.lnk" target="blank_" rel="noopener"><img src="${
        url
      }.jpg" alt=""/></a></div>`;
    },
    getContent() {
      let { content } = this;

      if (this.isMobile && this.contentMobile) {
        content = this.contentMobile;
      }

      content = content.replace('https://pictures.rr-dev.ru/', 'https://mkt.russiarunning.com/');
      return content;
    },
    createUUID() {
      let dt = new Date().getTime();
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
        (c) => {
          // eslint-disable-next-line no-bitwise
          const r = (dt + Math.random() * 16) % 16 | 0;
          dt = Math.floor(dt / 16);
          // eslint-disable-next-line no-mixed-operators,no-bitwise
          return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    },
  },
  mounted() {
    if (this.content) {
      this.isMobile = window.innerWidth < 768;
      this.putContent();
    }
  },
};
</script>

<style lang="scss">
.banner-block {

  img {
    display: block;
    width: 100%;
  }

  &_top {
    margin-bottom: 24px;
  }
}
</style>
