import moment from 'moment';

const ranges = {
  thisWeekend() {
    return [
      moment().weekday(5).toDate(),
      moment().weekday(6).toDate(),
    ];
  },
  thisWeek() {
    return [
      moment().weekday(0).toDate(),
      moment().weekday(6).toDate(),
    ];
  },
  nextWeek() {
    return [
      moment().weekday(7).toDate(),
      moment().weekday(13).toDate(),
    ];
  },
  thisMonth() {
    return [
      moment().startOf('month').toDate(),
      moment().endOf('month').toDate(),
    ];
  },
  nextMonth() {
    const start = moment().startOf('month').add(1, 'months');
    const end = start.clone().endOf('month').toDate();

    return [
      start.toDate(),
      end,
    ];
  },
  thisYear() {
    return [
      moment().startOf('year').toDate(),
      moment().endOf('year').toDate(),
    ];
  },
  nextYear() {
    const start = moment().startOf('year').add(1, 'years');
    const end = start.clone().endOf('year').toDate();

    return [
      start.toDate(),
      end,
    ];
  },
};

function getDate(type) {
  if (!ranges[type]) {
    return null;
  }
  return ranges[type]();
}

export {
  // eslint-disable-next-line import/prefer-default-export
  getDate,
};
