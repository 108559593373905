<template>
  <div class="rr-notification" v-show="isOpen">
    <div class="rr-notification__container">
      <div class="rr-notification__close">
        <button
          :class="`${btnPrefix}button ${btnPrefix}button_square ${btnPrefix}button_sm ${btnPrefix}button_clear`"
          @click.stop="dismiss"
        >
          <span :class="`${btnPrefix}button__icon`">
            <icon icon-name="close"></icon>
          </span>
        </button>
      </div>
      <div class="rr-notification__body">
        <div class="rr-notification__img" v-if="img">
          <img :src="img" alt="">
        </div>
        <div class="rr-notification__content">
          <div class="rr-notification__title" v-html="title"></div>
          <div class="rr-notification__text" v-html="text"></div>
        </div>
      </div>
      <div class="rr-notification__actions" v-if="!hideActions">
        <div class="rr-notification__decline" @click.stop="decline">
          <button class="link link_light text-sm">{{ declineText || 'Не интересно' }}</button>
        </div>
        <div class="rr-notification__confirm" @click.stop="confirm">
          <button :class="`${btnPrefix}button ${btnPrefix}button_primary ${btnPrefix}button_sm`">
            <span :class="`${btnPrefix}button__text`">
              {{ confirmText || 'Подтвердить' }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RrNotification',
  props: {
    title: String,
    text: String,
    img: String,
    confirmText: String,
    declineText: String,
    hideActions: Boolean,
    btnPrefix: {
      type: String,
      default: 'rr-',
    },
  },
  data() {
    return {
      isOpen: true,
    };
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
      this.$emit('closed');
    },
    dismiss() {
      this.$emit('dismiss');
      this.close();
    },
    decline() {
      this.$emit('decline');
      this.close();
    },
    confirm() {
      this.$emit('confirm');
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/utils/vars";
@import '~@rr-component-library/common/src/scss/vars.scss';
@import "@/assets/scss/utils/mixins";

.rr-notification {
  display: inline-block;
  vertical-align: top;
  @include base-sm-shadow;
  padding: 12px 16px;
  max-width: 480px;
  position: relative;
  text-align: left;
  pointer-events: auto;

  @media(min-width: breakpoint(md)) {
    min-width: 360px;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    margin: 8px;
  }

  &__body {
    display: flex;
    align-items: flex-start;
  }

  &__img {
    width: 48px;
    flex: 0 0 auto;
    margin-right: 16px;

    @media (min-width: breakpoint(md)) {
      width: 64px;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  &__content {
    flex: 1 1 100%;
    min-width: 0;
    align-self: center;
  }

  &__title {
    @include text-md;
    font-weight: $font-weight-semibold;
    margin-bottom: 4px;
    margin-right: 32px;
  }

  &__text {
    @include text-sm;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $color-black-15;
    padding-top: 12px;
    margin-top: 16px;
  }
}
</style>
