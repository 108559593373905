import axios from 'axios';

// eslint-disable-next-line no-underscore-dangle
const locale = window.__LANGUAGE__ ?? process.env.VUE_APP_I18N_LOCALE;
const { CancelToken } = axios;

let cancelGetEventsList;
let cancelGetEventsMarkers;
let cancelGetEventDetails;
let cancelSearchEvents;
let cancelGetRaceMembers;
let cancelGetSeriesList;

const apiClient = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}api/events`,
});

export default {
  getEventsList(params) {
    if (cancelGetEventsList) {
      cancelGetEventsList('canceled');
    }
    return apiClient.post(`/list/${locale}`, {
      ...params,
      IntoRayRussiaRunnung: false,
      HidePastEvents: false,
    }, {
      cancelToken: new CancelToken(((c) => {
        cancelGetEventsList = c;
      })),
    });
  },
  getWeather({
    temperatureType,
    latitude,
    longitude,
    day,
  }) {
    return apiClient.post('/weather', {
      temperatureType: temperatureType || 'celsius',
      latitude,
      longitude,
      day,
    });
  },
  getFilters() {
    return apiClient.post(`/get-filter/${locale}`);
  },
  searchEvents({ title, currentSeason, showPast }) {
    const params = `?titlePattern=${title}&forCurrentSeason=${currentSeason}&showPast=${showPast}`;
    return apiClient.get(`/find-by-title/${locale}${params}`, {
      cancelToken: new CancelToken(((c) => {
        cancelSearchEvents = c;
      })),
    });
  },
  cancelSearchEvents() {
    if (cancelSearchEvents) {
      cancelSearchEvents('canceled');
    }
  },
  getEventsMarkers(params) {
    if (cancelGetEventsMarkers) {
      cancelGetEventsMarkers('canceled');
    }
    return apiClient.post(`/markers/${locale}`, {
      SortRule: {
        Type: 0,
        Direction: 1,
      },
      ...params,
      IntoRayRussiaRunnung: false,
      ResultsCalculated: false,
      Language: locale,
    }, {
      cancelToken: new CancelToken(((c) => {
        cancelGetEventsMarkers = c;
      })),
    });
  },
  getEventDetails(eventCode) {
    if (cancelGetEventDetails) {
      cancelGetEventDetails('canceled');
    }
    return apiClient.post(`/detailes/${locale}`, {
      eventCode,
      CurrentUserId: window.userInfo ? window.userInfo.Id : null,
    }, {
      cancelToken: new CancelToken(((c) => {
        cancelGetEventDetails = c;
      })),
    });
  },
  getEventParticipantRating(eventId) {
    return apiClient.post(`/participant-ratings/${locale}?eventId=${eventId}`);
  },
  getRaceMembers({
    EventCode,
    RaceCode,
    MemberNameFilter,
    ClubeId,
    CityId,
    TeamId,
    Count,
    Offset,
    PrivateDateToken,
  }) {
    if (cancelGetRaceMembers) {
      cancelGetRaceMembers('canceled');
    }
    return apiClient.post(`/race-members/${locale}`, {
      EventCode,
      RaceCode,
      MemberNameFilter,
      ClubeId,
      CityId,
      TeamId,
      OnlyMembers: true,
      Count,
      Offset,
      PrivateDateToken,
    }, {
      cancelToken: new CancelToken(((c) => {
        cancelGetRaceMembers = c;
      })),
    });
  },
  getRegistrations(currentUserId) {
    return apiClient.post(`/user-registrations?currentUserId=${currentUserId}`);
  },
  getSeriesList(params) {
    if (cancelGetSeriesList) {
      cancelGetSeriesList('canceled');
    }
    return apiClient.post(`/series/${locale}`, {
      ...params,
      IntoRayRussiaRunnung: false,
      HidePastEvents: false,
    }, {
      cancelToken: new CancelToken(((c) => {
        cancelGetSeriesList = c;
      })),
    });
  },
  getSeriesInfo(seriesCode) {
    return apiClient.post(`/series/${seriesCode}/${locale}`);
  },
  findPlaces(searchString) {
    return apiClient.get(`/find-places/${locale}?searchString=${searchString}`);
  },
  getLiveCount(baseUrl) {
    return apiClient.post(`${baseUrl}/api/events/live-count`);
  },
};
