import i18n from '@/i18n';

export default [
  {
    id: '0',
    name: i18n.t('events.nav.future'),
  },
  {
    id: '1',
    name: i18n.t('events.nav.justNow'),
    hidden: window.vm?.AnyCurrentEvents === false,
  },
].filter((o) => !o.hidden);
