<template>
  <div class="series-card">
    <div
      class="series-card__cover"
    >
      <div
        class="series-card__cover-img"
        :class="{'series-card__cover-img_default': !hasImage}"
        v-show="cover"
        :style="{ backgroundImage: `url(${cover})` }"
      ></div>
      <router-link
        class="series-card__cover-link"
        :to="{name: 'Series', params: {seriesCode: code}}"
      ></router-link>
      <div class="series-card__cover-overlay"></div>
      <div class="series-card__cover-content">
        <div class="series-card__main">
          <div class="series-card__title group-title group-title_sm group-title_sm_mb">{{ $t('series_card.title') }}</div>
          <h3 class="series-card__name">{{ name }}</h3>
        </div>
        <div class="series-card__extra">
          <Metric
            class="d-none d-lg-block"
            :value="`${activeCount || 0}`"
            :units="`<span class='color-muted'>/ ${totalCount || 0}</span>`"
            :subtitle="$t('series_card.events')"
          ></Metric>
          <div class="series-card__metric d-lg-none">
            <icon icon-name="calendar" w="24" h="24"></icon>&nbsp;&nbsp;
            <span class="text-lg">{{ activeCount }}<span class="text-sm color-muted"> / {{ totalCount }}</span></span>
          </div>
        </div>
      </div>
    </div>
    <div class="series-card__champs" v-show="activeCount">
      <div :id="`series-slider-${id}`"
           class="series-card__slider"
           :class="{'series-card__slider_arrows': !disabled}"
      >
        <div class="series-card__slider-wrapper swiper-wrapper">
          <slot
            name="slides"
            slide-class="series-card__slide swiper-slide"
          ></slot>
        </div>
        <div
          class="series-card__pagination swiper-pagination"
          :class="{'series-card__pagination_visible': !disabled}"
          v-show="!disabled"
        ></div>
        <Button
          class="series-card__prev rr-button_shadow"
          variant="clear"
          size="lg"
          shape="circle"
          icon-left="left"
        ></Button>
        <Button
          class="series-card__next rr-button_shadow"
          variant="clear"
          size="lg"
          shape="circle"
          icon-left="right"
        ></Button>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper, { Navigation, Pagination } from 'swiper';
import Metric from '@rr-component-library/metric/src/main';

Swiper.use([Navigation, Pagination]);

export default {
  name: 'SeriesCard',
  props: {
    code: String,
    name: String,
    image: String,
    activeCount: Number,
    totalCount: Number,
  },
  components: {
    Metric,
  },
  data() {
    return {
      id: null,
      slider: {},
      sliderCreated: false,
      cover: null,
      hasImage: false,
    };
  },
  computed: {
    disabled() {
      return this.slider.isBeginning && this.slider.isEnd;
    },
  },
  methods: {
    setCover() {
      const url = this.image && this.image.replace('/-x-/', '/-x400/');
      const imgUrl = '/Content/images/championship/championship-background.jpg';
      if (!url) {
        this.cover = imgUrl;
        return;
      }
      const img = new Image();
      img.onload = () => {
        this.cover = url;
        this.hasImage = true;
      };
      img.onerror = () => {
        this.cover = url;
      };
      img.src = url;
    },
    createSlider() {
      this.slider = new Swiper(`#series-slider-${this.id}`, {
        simulateTouch: false,
        spaceBetween: 12,
        speed: 200,
        watchSlidesProgress: true,
        navigation: {
          nextEl: '.series-card__next',
          prevEl: '.series-card__prev',
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
        breakpoints: {
          1200: {
            slidesPerView: 2,
            slidesPerGroup: 2,
          },
        },
      });
      this.sliderCreated = true;
    },
  },
  watch: {
    disabled: {
      handler() {
        if (this.sliderCreated) {
          setTimeout(() => {
            this.slider.update();
          });
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.setCover();
    if (this.activeCount) {
      setTimeout(this.createSlider);
    }
  },
  created() {
    if (!this.$root.sliderId) {
      this.$root.sliderId = 0;
    }
    this.$root.sliderId += 1;
    this.id = this.$root.sliderId;
  },
};
</script>

<style lang="scss" scoped>
.series-card {
  background-color: $color-white;
  box-shadow: $elevation-deth-4;
  border-radius: 4px;
  height: 100%;
  overflow: hidden;

  &__cover {
    display: flex;
    flex-direction: column;
    min-height: 176px;
    padding: 20px 16px;
    position: relative;

    @media (min-width: breakpoint(md)) {
      min-height: 160px;
    }

    @media (min-width: breakpoint(lg)) {
      min-height: 196px;
    }
  }

  &__cover-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
  }

  &__cover-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    animation: rr-fade-in 0.15s;

    &_default {
      background-position: top left;
    }
  }

  &__cover-overlay {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.65) 50%, rgba(0, 0, 0, 0.65) 50%), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.25) 100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__cover-content {
    flex: 0 0 auto;
    width: 100%;
    margin-top: auto;
    position: relative;

    @media (min-width: breakpoint(lg)) {
      display: flex;
      align-items: flex-end;
    }
  }

  &__main {
    flex: 1 1 100%;
    min-width: 0;
    color: $color-white;
    align-self: center;
  }

  &__name, &__title {
    color: inherit;
  }

  &__title {
    font-size: 12px;
    line-height: 18px;
    font-weight: $font-weight-semibold;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin-bottom: 0.5rem;
  }

  &__extra {
    margin-top: 1rem;

    @media (min-width: breakpoint(lg)) {
      margin-top: 0;
      margin-left: 40px;
    }
  }

  &__champs {
    padding: 20px 16px;
  }

  &__slider {
    flex: 1 1 0;
    min-width: 0;
    position: relative;
    overflow: hidden;
    margin: 0 -4px;

    @media (min-width: breakpoint(md)) {

      &_arrows {
        padding: 0 76px;

        &::before, &::after {
          content: "";
        }
      }
    }

    &::before, &::after {
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      background-color: $color-white;
      width: 72px;
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }
  }

  &__slider-wrapper {
    display: flex;
    flex-wrap: nowrap;
  }

  &__slide {
    flex: 0 0 auto;
    max-width: 100%;
    width: 100%;
  }

  &__prev, &__next {
    display: none;
    align-self: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    margin: 0 4px;
    user-select: none;

    .series-card__pagination_visible ~ & {
      margin-top: -12px;
    }

    &.swiper-button-disabled {
      opacity: 0;
    }

    @media (min-width: breakpoint(md)) {
      display: block;
    }
  }

  &__prev {
    left: 0;
  }

  &__next {
    right: 0;
  }
}
</style>

<style lang="scss">
.series-card {

  &__metric {
    line-height: 40px;
    color: $color-black;
    display: inline-flex;
    align-items: center;
    background-color: $color-white;
    border-radius: 99px;
    box-shadow: $elevation-deth-4;
    padding: 0 12px;
    font-weight: $font-weight-semibold;
  }
}
</style>
