<template>
  <div class="events-map custom-map">
    <div class="overlay overlay_light overlay_flex"
         v-show="loading"
         style="z-index: 2">
      <div class="overlay__content overlay__content_top">
        <div class="loader"></div>
      </div>
    </div>
    <div
      id="eventsMap"
      class="events-map__container"
    >
    </div>
  </div>
</template>

<script>
import L from 'leaflet';
import eventsService from '@/services/eventsService';
import { mapGetters } from 'vuex';

export default {
  name: 'EventsMap',
  props: {
    eventsType: String,
  },
  data() {
    return {
      markersLayer: null,
      cached: {},
      updId: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters('settings', ['theme']),
  },
  methods: {
    onFiltersChange(data, id) {
      this.updId = id;
      if (!data.parse) {
        this.$emit('updateQuery');
        this.loadMarkers();
      } else {
        this.loadMarkers();
      }
    },
    init() {
      this.createMap();
      this.loadMarkers();
    },
    loadMarkers() {
      this.loading = true;
      if (this.markersLayer) {
        this.markersLayer.clearLayers();
      }
      setTimeout(() => {
        const data = this.getRequestParams();
        eventsService.getEventsMarkers(data)
          .then((res) => {
            this.setMarkers(res.data.Items);
            this.loading = false;
          });
      });
    },
    getRequestParams() {
      const filters = this.$parent.getFiltersRequestParams();
      return {
        EventsLoaderType: this.eventsType ? +this.eventsType : null,
        HidePastEvents: +this.eventsType === 4,
        UseTenantBeneficiaryCode: this.theme !== 'rr',
        ...filters,
      };
    },
    createMap() {
      if (this.created) {
        return;
      }

      this.created = true;
      this.map = L.map('eventsMap');

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 18,
        accessToken: 'pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw',
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(this.map);

      this.map.setView([59.8716679, 46.0222179], 5);
    },
    setMarkers(items) {
      if (!this.markersLayer) {
        this.markersLayer = L.layerGroup().addTo(this.map);
      }

      items.forEach((item) => {
        if (item.lat && item.lon) {
          const marker = L.marker([
            item.lat,
            item.lon,
          ], {
            title: item.t,
            id: item.c,
          })
            .addTo(this.markersLayer);
          this.addPopup(marker);
        }
      });

      if (this.markersLayer) {
        const bounds = L.latLngBounds(items);
        this.map.fitBounds(bounds, {
          maxZoom: 8,
        });
      }
    },
    addPopup(marker) {
      marker.addEventListener('click', () => {
        // eslint-disable-next-line no-underscore-dangle
        if (marker._popup !== undefined) {
          marker.unbindPopup();
        }

        if (this.cached[marker.options.id]) {
          const html = this.getPopupHtml(this.cached[marker.options.id]);
          marker.bindPopup(html, { maxWidth: 300 });
          marker.openPopup();
          return;
        }

        marker.bindPopup(`${this.$t('events.map.loading')}...`, { maxWidth: 300 });
        marker.openPopup();

        eventsService.getEventDetails(marker.options.id)
          .then((res) => {
            const { data } = res;
            const html = this.getPopupHtml(data);
            marker.bindPopup(html, { maxWidth: 300 });
            marker.openPopup();
            this.cached[data.Code] = data;
          });
      });
    },
    getPopupHtml(data) {
      const date = this.$moment(data.BeginDate).format('DD MMMM YYYY');

      window.onMarkerClick = () => {
        window.open(`/event/${data.Code}`);
      };

      return `
        <div class="event-marker">
          <div class="event-marker__date text-sm color-primary">
            <b>${date}</b>
          </div>
          <div
            class="event-marker__title link text-lg"
            title='${data.Title}'
            onclick="onMarkerClick()"
          >
            <b>${data.Title}</b>
          </div>
          <div
            class="event-marker__place text-sm"
            title="${data.Place}"
          >
            ${data.Place}
          </div>
        </div>
      `;
    },
    toggle(show, id) {
      if (!show) {
        return;
      }
      if (!this.created) {
        this.updId = id;
        setTimeout(this.init, 10);
      } else if (this.updId !== id) {
        this.updId = id;
        this.loadMarkers();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .events-map {
    width: 100%;
    background-color: $color-white;
    position: relative;

    &__container {
      width: 100%;
      height: 400px;

      @media (min-width: breakpoint(md)) {
        height: 500px;
      }
    }
  }
</style>

<style lang="scss">
  .custom-map {

    .leaflet-popup-content-wrapper {
      font-family: $font-family-base;
      border-radius: 4px;
    }

    .leaflet-popup-content {
      margin: 0;
      padding: 12px 16px;
    }
  }

  .event-marker {

    .link {
      color: inherit;

      &:hover {
        color: $color-primary;
      }
    }

    &__title {
      margin-top: 4px;
      margin-bottom: 16px;
      max-height: 2.8em;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__place {
      max-height: 3.1em;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
</style>
