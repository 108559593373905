<template>
  <div class="simple-event-card">
    <div class="simple-event-card__info">
      <div class="simple-event-card__header">
        <div class="simple-event-card__header-col">
          <b class="color-primary" v-html="eventDate"></b>
        </div>
        <!-- Badge : BEGIN -->
        <div
          class="event-card__header-col"
          v-if="eventRegistration.noPlaces || eventRegistration.fewPlaces"
        >
          <Badge
            v-if="eventRegistration.noPlaces"
            :text="$t('race.noPlaces')"
            type="muted"
          >
          </Badge>
          <Badge
            v-else-if="eventRegistration.fewPlaces"
            :text="$t('race.fewPlaces')"
            type="warning"
            icon="fire"
          >
          </Badge>
        </div>
        <!-- Badge : END -->
      </div>
      <div class="simple-event-card__body">
        <router-link
          class="simple-event-card__name text-lg"
          v-html="name"
          :title="name"
          :to="{ name: 'Event', params: { code: code } }"
        ></router-link>
      </div>
      <div
        class="simple-event-card__footer"
        v-if="location"
      >
        <div class="text-with-icon">
          <icon
            class="icon color-muted"
            icon-name="environment"
            w="16"
            h="16"
          />
          <span
            class="simple-event-card__location text-sm"
            :title="location"
          >{{ location }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDateRange } from '@/assets/js/helpers';
import Badge from '@/components/Badge.vue';

export default {
  name: 'SimpleEventCard',
  components: {
    Badge,
  },
  props: {
    code: String,
    name: String,
    beginDate: String,
    endDate: String,
    location: String,
    eventRaces: Array,
  },
  data() {
    return {
      races: [],
      eventRegistration: {
        noPlaces: false,
        fewPlaces: false,
      },
    };
  },
  computed: {
    eventDate() {
      return formatDateRange([
        this.$moment(this.beginDate),
        this.$moment(this.endDate),
      ]);
    },
  },
  methods: {
    setRaces() {
      let races = this.eventRaces;

      races = races.map((race) => {
        const {
          offlineRegistration,
          registrationStatus,
          registrationCloseDate,
          disableRegistration,
          registrationsCount,
          participantsCount,
        } = race;

        const registration = {
          notOpened: registrationStatus === 1,
          active: registrationStatus === 2,
          closed: registrationStatus === 3 || (registrationCloseDate && registrationCloseDate < new Date()),
          disabled: disableRegistration,
          available: registrationStatus === 2 && !disableRegistration,
          offlineAvailable: offlineRegistration,
          noPlaces: registrationsCount === 0,
          fewPlaces: false,
        };

        if (typeof registrationsCount === 'number' && typeof participantsCount === 'number') {
          const total = registrationsCount + participantsCount;
          const availablePercent = (registrationsCount / total) * 100;
          registration.fewPlaces = availablePercent ? availablePercent < 10 : false;
        }

        return {
          ...race,
          registration,
        };
      });

      this.races = races;
    },
    setEventRegistration() {
      const races = this.races || [];
      const eventRegistration = {
        noPlaces: true,
        fewPlaces: true,
      };

      races.some((race) => {
        const { registration } = race;
        const noPlaces = !registration.notOpened && (!registration.available || registration.noPlaces);

        if (registration.offlineAvailable || !noPlaces) {
          eventRegistration.noPlaces = false;
        }
        if (registration.offlineAvailable || !registration.fewPlaces) {
          eventRegistration.fewPlaces = false;
        }

        // Stop loop when values = false
        return !eventRegistration.noPlaces && !eventRegistration.fewPlaces;
      });

      this.eventRegistration = eventRegistration;
    },
  },
  mounted() {
    if (this.eventRaces && this.eventRaces.length) {
      this.setRaces();
      this.setEventRegistration();
    }
  },
};
</script>

<style lang="scss" scoped>
.simple-event-card {
  display: flex;
  background-color: $color-white;
  border: 2px solid $color-black-15;
  border-radius: 4px;
  padding: 14px;
  min-height: 150px;
  height: 100%;

  &__info {
    flex: 0 0 auto;
    flex-grow: 1;
    max-width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.25rem;
  }

  &__header-col {
    flex: 0 0 auto;
    max-width: 100%;
  }

  &__body {
    margin-bottom: auto;
  }

  &__footer {
    margin-top: 16px;
  }

  &__name {
    display: block;
    font-weight: $font-weight-semibold;
    color: inherit;
    text-decoration: none;
    transition: $transition-default;
    max-height: 78px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (min-width: breakpoint(sm)) {
      max-height: 52px;
      -webkit-line-clamp: 2;
    }

    .no-touch &:hover {
      color: $color-primary;
    }
  }

  &__location {
    white-space: nowrap;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1 1 auto;
  }
}
</style>
