<template>
  <svg
    :class="svgClass"
    :width="w"
    :height="h"
    aria-hidden="true"
  >
    <use :xlink:href="name"></use>
  </svg>
</template>

<script>
const requireAll = (requireContext) => requireContext.keys().map(requireContext);
const req = require.context('../assets/img/svg', false, /\.svg$/);
requireAll(req);

export default {
  name: 'Icon',
  props: {
    iconName: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      default: '',
    },
    w: {
      type: String,
      default: null,
    },
    h: {
      type: String,
      default: null,
    },
  },
  computed: {
    name() {
      const icon = this.iconName;
      return icon ? `#icon-${icon}` : '';
    },
    svgClass() {
      const { className } = this;
      return className ? `svg-icon ${className}` : 'svg-icon';
    },
  },
};
</script>

<style>
  .svg-icon {
    fill: currentColor;
    overflow: hidden;
  }
</style>
