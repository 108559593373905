<template>
  <div>
    <div class="section__item">
      <div class="grid grid_v-margins">
        <template v-for="(series, i) in events">
          <div
            class="grid__col"
            :key="series.Id"
          >
            <SeriesCard
              :code="series.Code || series.Id"
              :name="series.Title"
              :image="series.HeaderBanner"
              :champs="series.Events"
              :active-count="series.Events.length"
              :total-count="series.TotalEvents"
            >
              <template v-slot:slides="slotProps">
                <div
                  :class="slotProps.slideClass"
                  class="series-card__slide swiper-slide"
                  v-for="event in series.Events"
                  :key="event.Id"
                >
                  <SimpleEventCard
                    :code="event.Code || event.Id"
                    :name="event.Title"
                    :beginDate="event.BeginDate"
                    :endDate="event.EndDate"
                    :location="event.Place"
                    :event-races="mapEventRaces(event.Races)"
                  ></SimpleEventCard>
                </div>
              </template>
            </SeriesCard>
          </div>
          <div
            class="grid__col"
            :key="'banner-' + i"
            v-if="theme === 'rr' && banner && (i - 1) % 12 === 0"
          >
            <Banner
              :content="banner"
              :contentMobile="bannerMobile"
            />
          </div>
        </template>
      </div>
      <div
        class="loader"
        v-show="loading"
      ></div>
      <div v-show="!loading && (!pagination.totalItems || !events.length)">
        <slot name="noResults">
          <section
            class="empty-state"
          >
            <div class="section__group mt-0">
              <div class="section__item">{{ $t('events.noResults') }}</div>
            </div>
          </section>
        </slot>
      </div>
    </div>
    <div
      v-if="pagination.totalItems > pagination.itemsPerPage"
      v-show="!loading"
    >
      <div
        class="section__item"
        v-show="pagination.page + 1 < totalPages"
      >
        <Button
          variant="secondary"
          size="lg"
          wide
          :text="$t('events.showMore')"
          @click="showMore"
        ></Button>
      </div>
      <div
        class="section__item"
      >
        <Pagination
          :page="pagination.page"
          :totalItems="pagination.totalItems"
          :itemsPerPage="pagination.itemsPerPage"
          @change="changePage"
        ></Pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import eventsService from '@/services/eventsService';
import SeriesCard from '@/sections/SeriesCard.vue';
import SimpleEventCard from '@/sections/SimpleEventCard.vue';
import Pagination from '@/components/Pagination.vue';
import Banner from '@/components/Banner.vue';

export default {
  name: 'events',
  components: {
    SeriesCard,
    SimpleEventCard,
    Pagination,
    Banner,
  },
  props: {
    banner: String,
    bannerMobile: String,
  },
  data() {
    return {
      loading: true,
      events: [],
      pagination: {
        page: 0,
        itemsPerPage: 12,
        totalItems: 0,
      },
      updId: null,
    };
  },
  computed: {
    ...mapGetters('settings', ['theme']),
    totalPages() {
      return Math.ceil(this.pagination.totalItems / this.pagination.itemsPerPage);
    },
  },
  methods: {
    mapEventRaces(races) {
      if (!races) {
        return [];
      }
      return races.map((race) => ({
        id: race.Id,
        name: race.Name,
        registrationCloseDate: race.RegistrationCloseDate,
        registrationStatus: race.RegistrationDateState,
        offlineRegistration: race.EnableOfflineRegistration,
        disableRegistration: race.DisableRegistration,
        registrationsCount: race.AvailableRegistrationsCount,
        participantsCount: race.ParticipantsCount,
      }));
    },
    onFiltersChange(data, id) {
      this.updId = id;
      this.created = true;
      this.events = [];
      if (!data.parse) {
        this.pagination.page = 0;
        this.onParamsChange();
      } else {
        this.getEvents();
      }
    },
    showMore() {
      this.pagination.page += 1;
      this.onParamsChange();
    },
    changePage(page) {
      this.pagination.page = page;
      this.events = [];
      this.onParamsChange();
      window.scrollTo(0, 0);
    },
    getRequestParams() {
      const pagination = {
        Skip: this.pagination.page * this.pagination.itemsPerPage,
        Take: this.pagination.itemsPerPage,
      };
      const filters = this.$parent.getFiltersRequestParams();
      return {
        ...pagination,
        ...filters,
      };
    },
    onParamsChange() {
      this.$emit('updateQuery');
      this.getEvents();
    },
    getEvents() {
      this.loading = true;
      setTimeout(() => {
        const data = this.getRequestParams();
        eventsService.getSeriesList(data)
          .then((res) => {
            this.events.push(...res.data.Items);
            this.pagination.totalItems = res.data.TotalCount;
            this.loading = false;
          });
      });
    },
    toggle(show, id) {
      if (!show) {
        return;
      }
      if (this.created && this.updId !== id) {
        this.updId = id;
        this.events = [];
        this.pagination.page = 0;
        window.scrollTo(0, 0);
        this.getEvents();
      }
    },
  },
};
</script>
