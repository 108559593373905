<template>
  <div
    class="events-datepicker"
    :class="{'events-datepicker_mobile': mobile}"
    v-click-outside="onClickOutside"
  >
    <div class="input" ref="input" v-show="!mobile" @click="toggle">
      <input
        class="input__el"
        :placeholder="$t('filters.date.placeholder')"
        :value="formattedValue"
        readonly
      >
      <div class="input__border"></div>
      <div class="input__icons">
        <button
          class="input__icon input__icon_hover"
          type="button"
        >
          <icon icon-name="calendar"></icon>
        </button>
      </div>
    </div>
    <Dropdown
      trigger="input"
      :noListeners="true"
      placement="bottom-end"
      :hideOverlay="true"
      :usePopper="!mobile"
      ref="dropdown"
      :strategy="null"
    >
      <div class="calendar" slot="items">
        <div class="calendar__row">
          <div class="calendar__main">
            <date-picker
              ref="calendar"
              v-model="internalValue"
              :lang="$root.locale"
              :inline="true"
              :range="true"
              :confirm="false"
            ></date-picker>
          </div>
          <div class="calendar__presets">
            <div class="badges badges_column">
              <div
                class="badges__item"
                v-for="calendarPreset in presets"
                :key="calendarPreset.value"
              >
                <badge
                  class="w-100"
                  type="preset"
                  :text="calendarPreset.text"
                  @click="chooseDate(calendarPreset.value)"
                ></badge>
              </div>
            </div>
          </div>
        </div>
        <div class="calendar__footer">
          <div class="calendar__footer-item">
            <Button
              variant="secondary"
              :text="$t('filters.clear')"
              @click="reset"
            ></Button>
          </div>
          <div class="calendar__footer-item">
            <Button
              variant="primary"
              :text="$t('filters.apply')"
              @click="() => { confirmDate(); close(); }"
            ></Button>
          </div>
        </div>
      </div>
    </Dropdown>
  </div>
</template>

<script>
import Badge from '@/components/Badge.vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';
import 'vue2-datepicker/locale/en';
import '@/assets/js/datepicker/uz';
import { getDate } from '@/assets/js/calendar';

export default {
  name: 'EventsDatepicker',
  components: {
    Badge,
    DatePicker,
  },
  props: {
    mobile: Boolean,
  },
  data() {
    return {
      oldValue: null,
      presets: [
        { text: this.$t('filters.date.thisWeekend'), value: 'thisWeekend' },
        { text: this.$t('filters.date.thisWeek'), value: 'thisWeek' },
        { text: this.$t('filters.date.nextWeek'), value: 'nextWeek' },
        { text: this.$t('filters.date.thisMonth'), value: 'thisMonth' },
        { text: this.$t('filters.date.nextMonth'), value: 'nextMonth' },
        { text: this.$t('filters.date.thisYear'), value: 'thisYear' },
        { text: this.$t('filters.date.nextYear'), value: 'nextYear' },
      ],
      isActive: false,
      picker: {},
    };
  },
  computed: {
    internalValue: {
      get() {
        return this.$attrs.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    formattedValue() {
      return this.formatValue(this.picker.innerValue);
    },
  },
  methods: {
    formatValue(value) {
      if (!value || !value[0]) {
        return null;
      }

      let start = this.$moment(value[0]);
      let end = this.$moment(value[1]);

      start = start.isValid() ? start.format(`DD [${start.format('MMM').slice(0, 3)}] 'YY`) : null;
      end = end.isValid() ? end.format(`DD [${end.format('MMM').slice(0, 3)}] 'YY`) : start;

      if (!start || !end) {
        return null;
      }

      if (start === end) {
        return start;
      }

      return `${start} – ${end}`;
    },
    chooseDate(type) {
      this.calendar.currentValue = getDate(type);
      this.calendar.handleConfirmDate();
    },
    confirmDate() {
      const { innerValue } = this.picker;
      const isRange = this.$moment(innerValue[1]).isValid();

      this.calendar.currentValue = [
        innerValue[0],
        isRange ? innerValue[1] : innerValue[0],
      ];
      this.calendar.handleConfirmDate();
      this.refreshValue();
    },
    clearDate() {
      this.calendar.handleClear();
    },
    showCurrentMonth() {
      if (this.internalValue[0]) {
        const nextMonth = this.$moment(this.internalValue[0]).startOf('month').add(1, 'months').toDate();
        this.picker.calendars = [this.internalValue[0], nextMonth];
      }
    },
    toggle() {
      if (!this.isActive) {
        this.open();
        this.openDropdown();
        return;
      }
      this.onClickOutside();
    },
    open() {
      this.calendar.currentValue = this.internalValue;
      this.showCurrentMonth();
      this.oldValue = this.internalValue;
    },
    openDropdown() {
      this.dropdown.open();
      this.isActive = true;
    },
    reset() {
      this.clearDate();
      this.close();
      this.refreshValue();
    },
    close() {
      this.dropdown.close();
      this.isActive = false;
    },
    refreshValue() {
      this.picker.innerValue = this.internalValue;
    },
    onClickOutside() {
      if (this.isActive) {
        this.close();
        this.internalValue = this.oldValue;
        this.refreshValue();
      }
    },
  },
  mounted() {
    this.dropdown = this.$refs.dropdown;
    this.calendar = this.$refs.calendar;
    this.picker = this.$refs.calendar.$refs.picker;
    if (this.mobile) {
      this.dropdown.open();
    }
  },
};
</script>

<style lang="scss">
  .events-datepicker {

    &_mobile {

      .base-dropdown {
        position: relative;
        width: 100%;
        max-width: 100%;
        margin: 0;
        z-index: 1;

        &__block {
          padding: 0;
        }

        &__container {
          box-shadow: none;
          border: 0;
        }
      }

      .calendar {

        .mx-calendar {
          width: 100%;

          &:last-child {
            display: none;
          }
        }

        &__footer {
          display: none;
        }
      }
    }

    .base-dropdown__menu {
      max-height: 100%;
      padding: 0;
    }

    .mx-calendar-header-label {
      pointer-events: none;
    }

    .mx-datepicker-footer {
      display: none;
    }

    @media (max-width: breakpoint(lg)) {

      .calendar {

        &__row {
          flex-direction: column;
        }

        &__presets {
          border-top: 1px solid $color-black-9;
          border-left: 0;
          overflow-y: hidden;
          max-width: 604px;

          .badges {
            display: inline-flex;
            flex-direction: row;
            flex-wrap: nowrap;
          }
        }
      }
    }
  }
</style>
