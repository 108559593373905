/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */

const state = {
  settings: window.RR_APP_SETTINGS || {},
};

const mutations = {
  setThemeSettings(state) {
    state.settings = window.RR_APP_SETTINGS || {};
  },
};

const getters = {
  themeLoaded: (state) => state.settings.theme != null,
  theme: (state) => state.settings.theme || 'rr',
  themeImages: (state) => state.settings.images || {},
  themeUrls: (state) => state.settings.urls || {},
  themeName: (state) => state.settings.name || null,
  themeCurrency: (state) => state.settings.currency || '₽',
  userbackAvailable: (state) => !state.settings.theme || state.settings.theme === 'rr',
  themeMemberLinkDisabled: (state) => state.settings.isMemberLinkDisabled || true,
  themeSettings: (state) => state.settings.settings || {},
};

const actions = {
  setupTheme({ commit }) {
    commit('setThemeSettings');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
