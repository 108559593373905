import { render, staticRenderFns } from "./FilterItem.vue?vue&type=template&id=5db3e211&"
import script from "./FilterItem.vue?vue&type=script&lang=js&"
export * from "./FilterItem.vue?vue&type=script&lang=js&"
import style0 from "./FilterItem.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports