import DatePicker from 'vue2-datepicker';

const locale = {
  months: ['yanvar', 'fevral', 'mart', 'aprel', 'may', 'iyun', 'iyul', 'avgust', 'sentabr', 'oktabr', 'noyabr', 'dekabr'],
  monthsShort: ['yanvar', 'fevral', 'mart', 'aprel', 'may', 'iyun', 'iyul', 'avgust', 'sentabr', 'oktabr', 'noyabr', 'dekabr'],
  weekdays: ['yakshanba', 'dushanba', 'seshanba', 'chorshanba', 'payshanba', 'juma', 'shanba'],
  weekdaysShort: ['yak', 'dush', 'sesh', 'chor', 'pay', 'jum', 'shan'],
  weekdaysMin: ['ya', 'du', 'se', 'cho', 'pa', 'ju', 'sha'],
  firstDayOfWeek: 1,
  firstWeekContainsDate: 1,
};

const lang = {
  formatLocale: locale,
  yearFormat: 'YYYY',
  monthFormat: 'MMM',
  monthBeforeYear: true,
};
DatePicker.locale('uz', lang);

export default lang;
