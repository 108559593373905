<template>
  <div
    class="events-filters"
    :class="{'events-filters_active': isActive}"
  >
    <div
      class="events-filters__overlay overlay d-none d-md-block d-lg-none"
      @click="() => { cancel(); close(); }"
    ></div>
    <div class="events-filters__container">
      <!-- Desktop filters : BEGIN -->
      <div class="d-none d-md-block">
        <filter-item
          v-if="isEnabled('discipline')"
          id="discipline"
          :opened="state.discipline"
          :name="$t('filters.sportType.title')"
        >
          <Select
            class="multiselect_wrap"
            v-model="filters.discipline.val"
            :value="filters.discipline.val"
            :options="filters.discipline.options"
            trackBy="Id"
            label="Name"
            :placeholder="$t('filters.sportType.placeholder')"
            clearable
          ></Select>
        </filter-item>
        <filter-item
          v-if="isEnabled('dateFrom, dateTo')"
          id="date"
          :opened="state.date"
          :name="$t('filters.date.title')"
        >
          <events-datepicker v-model="date"></events-datepicker>
        </filter-item>
        <filter-item
          v-if="isEnabled('location')"
          id="location"
          :opened="state.location"
          :name="$t('filters.place.title')"
        >
          <Select
            class="multiselect_wrap"
            v-model="filters.location.val"
            :value="filters.location.val"
            :options="filters.location.options"
            :placeholder="$t('filters.place.placeholder')"
            trackBy="Name"
            label="Name"
            :loading="loadingLocation"
            searchable
            clearable
            @search="findLocation"
          >
            <template v-if="isNoResultSearchLocation" v-slot:noSearchResult>
              {{ $t('filters.place.startTyping') }}
            </template>
          </Select>
        </filter-item>
        <filter-item
          v-if="isEnabled('ratingFrom')"
          id="rating"
          :opened="state.rating"
          v-show="eventsType === 'current' || eventsType === 'results' || eventsType === 'series'"
          :name="$t('filters.starRating.title') + ' ⭐'"
        >
          <div class="filter__content-item">
            <div class="slider">
              <vue-slider
                class="rating-slider"
                v-model="rating"
                :data="filters.ratingFrom.options"
                :data-value="'id'"
                :data-label="'name'"
                tooltip="none"
                :dotSize="16"
                :contained="true"
                :duration="0.3"
                :dot-options="[{disabled: false}, {disabled: true}]"
                :lazy="true"
              ></vue-slider>
            </div>
          </div>
          <div class="filter__content-item">
            <Checkbox v-model="filters.confirmedRating.val">
              {{ $t('filters.starRating.onlyApproved') }}
            </Checkbox>
          </div>
        </filter-item>
        <filter-item
          v-if="isEnabled('nmo')"
          v-show="eventsType === 'current' || eventsType === 'results' || eventsType === 'series'"
          :opened="true"
          :toggleable="false"
        >
          <template slot="header">
            <label class="switch w-100">
              <span class="switch__content">
                <span class="switch__text">
                  <span class="filter__title">{{ $t('filters.nroEvents.title') }}</span>
                </span>
                <input
                  v-model="filters.nmo.val"
                  class="switch__input"
                  type="checkbox"
                  name="nbd-only"
                >
                <span class="switch__marker">
                  <icon icon-name="check-bold"></icon>
                  <icon icon-name="close"></icon>
                </span>
              </span>
            </label>
          </template>
          <div class="text-sm">{{ $t('filters.nroEvents.text') }}</div>
        </filter-item>
        <filter-item
          v-if="isEnabled('hasPlaces')"
          v-show="eventsType !== 'results' && eventsType !== 'series'"
          :opened="true"
          :toggleable="false"
        >
          <template slot="header">
            <label class="switch w-100">
              <span class="switch__content">
                <span class="switch__text">
                  <span class="filter__title">
                    {{ $t('filters.availableRegistrations.title') }}
                  </span>
                </span>
                <input
                  v-model="filters.hasPlaces.val"
                  class="switch__input"
                  type="checkbox"
                  name="with-places"
                >
                <span class="switch__marker">
                  <icon icon-name="check-bold"></icon>
                  <icon icon-name="close"></icon>
                </span>
              </span>
            </label>
          </template>
          <div class="text-sm">
            {{ $t('filters.availableRegistrations.text') }}
          </div>
        </filter-item>
        <filter-item
          v-if="isEnabled('fastId')"
          v-show="eventsType === 'current' || eventsType === 'series'"
          :opened="true"
          :toggleable="false"
        >
          <template slot="header">
            <label class="switch w-100">
              <span class="switch__content">
                <span class="switch__text">
                  <span class="filter__title">
                    {{ $t('filters.fastId.title') }}
                  </span>
                </span>
                <input
                  v-model="filters.fastId.val"
                  class="switch__input"
                  type="checkbox"
                  name="with-places"
                >
                <span class="switch__marker">
                  <icon icon-name="check-bold"></icon>
                  <icon icon-name="close"></icon>
                </span>
              </span>
            </label>
          </template>
          <div class="text-sm">
            {{ $t('filters.fastId.text') }}
          </div>
        </filter-item>
        <filter-item
          v-if="isEnabled('champs') && filters.champs.options.length > 0"
          id="champs"
          :opened="state.champs"
          :name="$t('filters.champs.title')"
        >
          <Checkbox
            v-for="item in filters.champs.options"
            :key="item.id"
            v-model="filters.champs.val"
            class="w-100"
            :value="item.id"
          >
            {{ item.name }}
          </Checkbox>
        </filter-item>
        <filter-item
          v-if="isEnabled('series')"
          id="series"
          v-show="eventsType !== 'series'"
          :opened="state.series"
          :name="$t('filters.series.title')"
        >
          <Select
            class="multiselect_wrap"
            v-model="filters.series.val"
            :value="filters.series.val"
            :options="filters.series.options"
            :placeholder="$t('filters.series.title')"
            trackBy="Id"
            label="Name"
          ></Select>
        </filter-item>
        <filter-item
          v-if="isEnabled('ageFrom, ageTo')"
          id="req"
          :opened="state.req"
          v-show="eventsType === 'current' || eventsType === 'results' || eventsType === 'series'"
          :name="$t('filters.requirements.title')"
        >
          <div class="filter__content-item">
            <div class="heading-line text-md">
              <span class="heading-line__text">{{$t('filters.age.title')}}</span>
            </div>
          </div>
          <div class="filter__content-item">
            <div class="badges">
              <div
                class="badges__item"
                v-for="agePreset in agePresets"
                :key="agePreset.value[0]"
              >
                <badge
                  type="preset"
                  :text="agePreset.text"
                  @click="age = agePreset.value"
                ></badge>
              </div>
            </div>
          </div>
          <div class="filter__content-item">
            <div class="slider">
              <vue-slider
                v-model="age"
                tooltip="none"
                :data="filters.ageFrom.options"
                :data-value="'id'"
                :data-label="'name'"
                :dotSize="16"
                :duration="0.3"
                :minRange="1"
                :contained="true"
                :included="true"
                :lazy="true"
              ></vue-slider>
            </div>
          </div>
        </filter-item>
        <div class="events-filters__footer">
          <div class="grid grid_v-margins grid_gutters-sm">
            <div class="grid__col grid__col_stretch">
              <Button
                variant="secondary"
                wide
                :text="$t('filters.clear')"
                @click="() => { reset(); close(); }"
              ></Button>
            </div>
            <div class="grid__col grid__col_stretch">
              <Button
                variant="primary"
                wide
                :text="$t('filters.show')"
                @click="apply"
              ></Button>
            </div>
          </div>
        </div>
      </div>
      <!-- Desktop filters : END -->
      <!-- Mobile filters : BEGIN -->
      <div class="d-md-none">
        <mobile-modal
          ref="mobileFilters"
          :name="$t('filters.title')"
          :count="filtersCount"
          :applyText="$t('filters.show')"
          :showResetBtn="true"
          @close="close"
          @cancel="cancel"
          @reset="reset"
          @apply="apply"
        >
          <div class="mobile-modal__full">
            <filter-item-extended
              v-if="isEnabled('discipline')"
              :name="$t('filters.sportType.title')"
              v-model="filters.discipline.val"
              :defaultValue="filters.discipline.defaultVal"
            >
              <template v-slot:value="props">
                {{ props.isNotDefault
                ? getOptionName(filters.discipline.options, filters.discipline.val, 'Id', 'Name')
                : $t('filters.sportType.empty') }}
              </template>
              <template slot="filter">
                <span></span>
                <Radio
                  v-for="item in filters.discipline.options"
                  :key="'discipline' + item.Id"
                  class="w-100"
                  v-model="filters.discipline.val"
                  :value="item.Id"
                >
                  {{ item.Name }}
                </Radio>
              </template>
            </filter-item-extended>
            <div class="events-filters__date-mobile">
              <filter-item-extended
                v-if="isEnabled('dateFrom, dateTo')"
                :name="$t('filters.date.title')"
                v-model="date"
                :defaultValue="[null, null]"
                :changed="dateChanged"
                @open="() => { $refs.mobileDatepicker.open() }"
                @reset="() => { $refs.mobileDatepicker.clearDate() }"
                @apply="() => { $refs.mobileDatepicker.confirmDate() } "
              >
                <template v-slot:value="props">
                  {{ props.isNotDefault ? formattedDate : $t('filters.date.empty') }}
                </template>
                <template slot="filter">
                  <div class="mobile-modal__full">
                    <events-datepicker
                      v-model="date"
                      ref="mobileDatepicker"
                      :mobile="true"
                    ></events-datepicker>
                  </div>
                </template>
              </filter-item-extended>
            </div>
            <filter-item-extended
              v-if="isEnabled('location')"
              :name="$t('filters.place.title')"
              :defaultValue="filters.location.defaultVal"
              v-model="filters.location.val"
              @open="filters.location.query = null"
              @opened="() => { $refs.locationInput.focus(); }"
            >
              <template v-slot:value="props">
                {{ props.isNotDefault
                ? getOptionName(filters.location.options, filters.location.val, 'Name', 'Name')
                : $t('filters.place.empty') }}
              </template>
              <template slot="filter">
                <div class="mobile-modal__full">
                  <div class="mobile-modal__body-item">
                    <label class="mobile-modal__input">
                      <input
                        v-model="filters.location.query"
                        ref="locationInput"
                        class="mobile-modal__input-el"
                        name="location"
                        type="text"
                        :placeholder="$t('filters.place.placeholder')"
                        autocomplete="off"
                        @input="findLocation($event.target.value)"
                      >
                    </label>
                  </div>
                  <div v-if="!locationSearch.length" class="mobile-modal__body-item mobile-modal__body-item_center">
                    <div v-if="isNoResultSearchLocation" class="mobile-modal__text-center">{{ $t('filters.place.startTyping') }}</div>
                    <div v-else class="mobile-modal__text-center">{{ $t('filters.place.nothingFound') }}</div>
                  </div>
                  <div v-else class="mobile-modal__body-item">
                    <div
                      class="base-dropdown__item"
                      v-for="item in locationSearch"
                      :key="item.Name"
                    >
                      <div
                        class="base-dropdown__link"
                        @click="() => {
                          filters.location.val = item.Name;
                          filters.location.query = item.Name;
                        }"
                      >
                        <icon
                          class="base-dropdown__icon color-muted"
                          icon-name="environment">
                        </icon>
                        <span class="base-dropdown__text text-normal">
                        {{ item.Name }}
                      </span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </filter-item-extended>
            <filter-item-extended
              v-if="isEnabled('ratingFrom')"
              v-show="eventsType === 'current' || eventsType === 'results' || eventsType === 'series'"
              :name="$t('filters.starRating.title') + ' ⭐'"
              v-model="rating"
            >
              <template slot="inlineFilter">
                <div class="filter-item-ext__body-item">
                  <div class="slider">
                    <vue-slider
                      class="rating-slider"
                      v-model="rating"
                      :data="filters.ratingFrom.options"
                      :data-value="'id'"
                      :data-label="'name'"
                      tooltip="none"
                      :dotSize="16"
                      :contained="true"
                      :duration="0.3"
                      :dot-options="[{disabled: false}, {disabled: true}]"
                      :lazy="true"
                    ></vue-slider>
                  </div>
                </div>
                <div class="filter-item-ext__body-item">
                  <Checkbox v-model="filters.confirmedRating.val">
                    {{ $t('filters.starRating.onlyApproved') }}
                  </Checkbox>
                </div>
              </template>
            </filter-item-extended>
            <filter-item-extended
              v-if="isEnabled('nmo')"
              v-show="eventsType === 'current' || eventsType === 'results' || eventsType === 'series'"
              :name="$t('filters.nroEvents.title')"
            >
              <template slot="value">
                <label class="switch w-100">
                <span class="switch__content">
                  <input
                    v-model="filters.nmo.val"
                    class="switch__input"
                    type="checkbox"
                    name="nbd-only"
                  >
                  <span class="switch__marker">
                    <icon icon-name="check-bold"></icon>
                    <icon icon-name="close"></icon>
                  </span>
                </span>
                </label>
              </template>
            </filter-item-extended>
            <filter-item-extended
              v-if="isEnabled('hasPlaces')"
              v-show="eventsType !== 'series' && eventsType !== 'results'"
              :name="$t('filters.availableRegistrations.title')"
            >
              <template slot="value">
                <label class="switch w-100">
                <span class="switch__content">
                  <input
                    v-model="filters.hasPlaces.val"
                    class="switch__input"
                    type="checkbox"
                    name="with-places"
                  >
                  <span class="switch__marker">
                    <icon icon-name="check-bold"></icon>
                    <icon icon-name="close"></icon>
                  </span>
                </span>
                </label>
              </template>
            </filter-item-extended>
            <filter-item-extended
              v-if="isEnabled('fastId')"
              v-show="eventsType === 'current' || eventsType === 'series'"
              :name="$t('filters.fastId.title')"
            >
              <template slot="value">
                <label class="switch w-100">
                <span class="switch__content">
                  <input
                    v-model="filters.fastId.val"
                    class="switch__input"
                    type="checkbox"
                    name="with-places"
                  >
                  <span class="switch__marker">
                    <icon icon-name="check-bold"></icon>
                    <icon icon-name="close"></icon>
                  </span>
                </span>
                </label>
              </template>
            </filter-item-extended>
            <filter-item-extended
              v-if="isEnabled('champs') && filters.champs.options.length > 0"
              :name="$t('filters.champs.title')"
              :defaultValue="filters.champs.defaultVal"
              v-model="filters.champs.val"
            >
              <template slot="value" v-if="filters.champs.val.length">
                {{ $t('filters.champs.selected') }} • {{ filters.champs.val.length }}
              </template>
              <template slot="filter">
                <Checkbox
                  v-for="item in filters.champs.options"
                  :key="item.id"
                  v-model="filters.champs.val"
                  class="w-100"
                  :value="item.id"
                >
                  {{ item.name }}
                </Checkbox>
              </template>
            </filter-item-extended>
            <filter-item-extended
              v-if="isEnabled('series')"
              :name="$t('filters.series.title')"
              v-show="eventsType !== 'series'"
              :defaultValue="filters.series.defaultVal"
              v-model="filters.series.val"
            >
              <template v-slot:value="props">
                {{ props.isNotDefault
                ? getOptionName(filters.series.options, filters.series.val, 'Id', 'Name')
                : $t('filters.series.empty') }}
              </template>
              <template slot="filter">
                <span></span>
                <Radio
                  v-for="item in filters.series.options"
                  :key="item.Id"
                  v-model="filters.series.val"
                  class="w-100"
                  :value="item.Id"
                >
                  {{ item.Name }}
                </Radio>
              </template>
            </filter-item-extended>
            <filter-item-extended
              v-if="isEnabled('ageFrom, ageTo')"
              v-show="eventsType === 'current' || eventsType === 'results' || eventsType === 'series'"
              :name="$t('filters.age.title')"
              v-model="age"
              :defaultValue="['0', '80']"
            >
              <template v-slot:value="props">
                {{ props.isNotDefault ? formattedAge : $t('filters.age.empty') }}
              </template>
              <template slot="filter">
                <div class="mobile-modal__body-item">
                  <div class="badges">
                    <div
                      class="badges__item"
                      v-for="agePreset in agePresets"
                      :key="agePreset.value[0]"
                    >
                      <badge
                        type="preset"
                        :text="agePreset.text"
                        @click="age = agePreset.value"
                      ></badge>
                    </div>
                  </div>
                </div>
                <div class="mobile-modal__body-item">
                  <div class="slider">
                    <vue-slider
                      v-model="age"
                      tooltip="none"
                      :data="filters.ageFrom.options"
                      :data-value="'id'"
                      :data-label="'name'"
                      :dotSize="16"
                      :duration="0.3"
                      :minRange="1"
                      :contained="true"
                      :included="true"
                      :lazy="true"
                    ></vue-slider>
                  </div>
                </div>
              </template>
            </filter-item-extended>
          </div>
        </mobile-modal>
      </div>
      <!-- Mobile filters : END -->
    </div>
  </div>
</template>

<script>
import FilterItem from '@/components/FilterItem.vue';
import FilterItemExtended from '@/components/FilterItemExtended.vue';
import Select from '@/components/Select.vue';
import Badge from '@/components/Badge.vue';
import EventsDatepicker from '@/components/EventsDatepicker.vue';
import MobileModal from '@/components/MobileModal.vue';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import { isEqual } from 'lodash';
import { mapState } from 'vuex';
import getData from '@/data';
import eventsService from '@/services/eventsService';

const ratingOptions = [
  { id: 'null', name: 'Нет' },
  { id: 'one', name: '3' },
  { id: 'oneplus', name: '3+' },
  { id: 'three', name: '4' },
  { id: 'threeplus', name: '4+' },
  { id: 'five', name: '5' },
  { id: 'fiveplus', name: '5+' },
];

const ratingArr = [];
ratingOptions.forEach((item) => {
  ratingArr.push(item.id);
});

const ageOptions = [
  { id: '0', name: '0' },
  { id: '12', name: '12' },
  { id: '18', name: '18' },
  { id: '30', name: '30' },
  { id: '60', name: '60' },
  { id: '80', name: '80+' },
];

const ageObj = {};
ageOptions.forEach((item) => {
  ageObj[item.id] = item.name;
});

const cachedValues = {};

export default {
  name: 'EventsFilters',
  components: {
    FilterItem,
    FilterItemExtended,
    Select,
    Badge,
    VueSlider,
    EventsDatepicker,
    MobileModal,
  },
  props: {
    eventsType: String,
  },
  data() {
    return {
      config: getData('events-filters-config.js') || {},
      isMounted: false,
      isActive: false,
      filters: {
        discipline: {
          val: null,
          defaultVal: null,
          options: [],
          key: 'Id',
        },
        dateFrom: {
          val: null,
          defaultVal: null,
        },
        dateTo: {
          val: null,
          defaultVal: null,
        },
        location: {
          val: null,
          defaultVal: null,
          options: [],
          query: null,
        },
        place: {
          val: null,
        },
        champs: {
          val: [],
          defaultVal: [],
          type: 'array',
          options: [
            // { id: 'rr', name: 'RussiaRunning' },
            // { id: 'sm', name: this.$t('filters.champs.sportmaster') },
          ],
        },
        series: {
          val: null,
          defaultVal: null,
          options: [],
          key: 'Id',
        },
        ratingFrom: {
          val: null,
          defaultVal: null,
          options: ratingOptions,
        },
        ageFrom: {
          val: null,
          defaultVal: null,
          options: ageOptions,
        },
        ageTo: {
          val: null,
          defaultVal: null,
          options: ageOptions,
        },
        confirmedRating: {
          val: false,
          defaultVal: false,
        },
        nmo: {
          val: false,
          defaultVal: false,
        },
        hasPlaces: {
          val: false,
          defaultVal: false,
        },
        fastId: {
          val: false,
          defaultVal: false,
        },
      },
      agePresets: [
        { text: this.$t('filters.age.children'), value: ['0', '12'] },
        { text: this.$t('filters.age.teens'), value: ['12', '18'] },
        { text: this.$t('filters.age.youth'), value: ['18', '30'] },
        { text: this.$t('filters.age.adults'), value: ['30', '60'] },
        { text: this.$t('filters.age.aged'), value: ['60', '80'] },
      ],
      state: {
        discipline: true,
        date: true,
        location: true,
      },
      loadingLocation: false,
      isNoResultSearchLocation: true,
    };
  },
  computed: {
    ...mapState('events', { eventsFilters: 'filters' }),
    enabledFilters() {
      return this.config.enabled || Object.keys(this.filters);
    },
    locationSearch() {
      const { options, query } = this.filters.location;
      if (options.length < 1 || !query) {
        return [];
      }

      const result = [];
      for (let i = 0; i < options.length && result.length < 6; i += 1) {
        const item = options[i];
        if (item.Name.toLowerCase().includes(query.toLowerCase())) {
          result.push(item);
        }
      }

      return result;
    },
    date: {
      get() {
        const from = this.filters.dateFrom.val;
        const to = this.filters.dateTo.val;
        return [
          from ? new Date(this.filters.dateFrom.val) : null,
          to ? new Date(this.filters.dateTo.val) : null,
        ];
      },
      set(value) {
        this.filters.dateFrom.val = value[0] && this.$moment(value[0]).format('YYYY-MM-DD');
        this.filters.dateTo.val = value[1] && this.$moment(value[1]).format('YYYY-MM-DD');
      },
    },
    rating: {
      get() {
        const { val, options } = this.filters.ratingFrom;
        const from = val || options[0].id;
        return [from, options[options.length - 1].id];
      },
      set(value) {
        const { options } = this.filters.ratingFrom;
        const [from] = value;
        this.filters.ratingFrom.val = from === options[0].id ? null : from;
      },
    },
    age: {
      get() {
        const { options } = this.filters.ageFrom;
        const from = this.filters.ageFrom.val || options[0].id;
        const to = this.filters.ageTo.val || options[options.length - 1].id;

        return [from, to];
      },
      set(value) {
        const { options } = this.filters.ageFrom;
        const [from, to] = value;
        this.filters.ageFrom.val = from === options[0].id ? null : from;
        this.filters.ageTo.val = to === options[options.length - 1].id ? null : to;
      },
    },
    formattedDate() {
      return this.date[0] && this.$refs.mobileDatepicker
        ? this.$refs.mobileDatepicker.formatValue(this.date)
        : null;
    },
    formattedAge() {
      const from = this.filters.ageFrom.val;
      const to = this.filters.ageTo.val;
      if (from === to) {
        return this.$t('filters.age.is', { val: ageObj[from] });
      }
      if (from === null) {
        return this.$t('filters.age.to', { val: ageObj[to] });
      }
      if (to === null) {
        return this.$t('filters.age.from', { val: ageObj[from] });
      }
      return this.$t('filters.age.fromTo', { from: ageObj[from], to: ageObj[to] });
    },
    dateChanged() {
      if (!this.isMounted) {
        return false;
      }
      const datepicker = this.$refs.mobileDatepicker;
      if (!this.$moment(datepicker.picker.innerValue[0]).isValid()) {
        return false;
      }
      return !isEqual(datepicker.picker.value, datepicker.picker.innerValue);
    },
    filtersCount() {
      let count = 0;
      const fields = ['discipline', 'location', 'nmo', 'hasPlaces', 'fastId', 'champs', 'series'];

      for (let i = 0; i < fields.length; i += 1) {
        const key = fields[i];
        const item = this.filters[key];
        if (item && (!isEqual(item.val, item.defaultVal))) {
          count += 1;
        }
      }

      if (
        !isEqual(this.rating, ['null', 'fiveplus'])
        || this.filters.confirmedRating.val !== this.filters.confirmedRating.defaultVal
      ) {
        count += 1;
      }

      if (
        this.filters.ageFrom.val !== this.filters.ageFrom.defaultVal
        || this.filters.ageTo.val !== this.filters.ageTo.defaultVal
      ) {
        count += 1;
      }

      if (
        this.filters.dateFrom.val !== this.filters.dateFrom.defaultVal
        || this.filters.dateTo.val !== this.filters.dateTo.defaultVal
      ) {
        count += 1;
      }

      return count;
    },
  },
  methods: {
    isEnabled(filterIds) {
      if (!filterIds) {
        return false;
      }
      const arr = filterIds
        .split(',')
        .map((id) => id.trim());

      return arr.some((id) => this.enabledFilters.indexOf(id) !== -1);
    },
    setFilters(filters) {
      this.filters.discipline.options = filters.d;
      this.filters.series.options = filters.s;
    },
    setFiltersLocation(filters) {
      this.filters.location.options = filters;
    },
    open() {
      this.isActive = true;
      this.$el.scrollTo(0, 0);
      if (this.$refs.mobileFilters) {
        this.$refs.mobileFilters.open();
      }
      document.body.style.overflow = 'hidden';
      this.saveValues();
    },
    close() {
      this.isActive = false;
      document.body.style.overflow = null;
    },
    saveValues() {
      Object.keys(this.filters).forEach((key) => {
        cachedValues[key] = this.filters[key].val;
      });
    },
    reset() {
      this.close();
      Object.keys(this.filters).forEach((key) => {
        this.filters[key].val = this.filters[key].defaultVal;
      });

      this.$emit('change', { reset: true });
    },
    cancel() {
      Object.keys(cachedValues).forEach((key) => {
        this.filters[key].val = cachedValues[key];
      });
    },
    getValues() {
      const value = {};
      const query = {};

      const srFrom = this.filters.ratingFrom.val;
      if (srFrom) {
        const i = ratingArr.indexOf(srFrom);
        value.starsArr = i > 0 ? ratingArr.slice(i) : null;
      }

      Object.keys(this.filters).forEach((key) => {
        if (key === 'place' && this.filters.location.val) {
          value.place = this.filters.location.val;
          value.query = this.filters.location.val;
        } else if (key === 'location') {
          const valLocation = this.filters.location.val;
          const current = this.filters.location.options.find((item) => item.Name === valLocation);

          if (current) {
            if (current.Id) {
              value.location = current.Id;
              query.location = current.Id;
            }
          }
        } else {
          const { val } = this.filters[key];
          value[key] = val;
          if (val !== this.filters[key].defaultVal) {
            query[key] = val;
          }
        }
      });

      return { value, query };
    },
    apply() {
      this.close();
      const data = this.getValues();

      this.$emit('change', data);
    },
    getOptionName(options, id, key, label) {
      if (!id) {
        return null;
      }
      const k = key || 'id';
      const l = label || 'name';

      for (let i = 0; i < options.length; i += 1) {
        const option = options[i];
        if (option[k] === id) {
          return option[l];
        }
      }
      return null;
    },
    async setPlace(val) {
      const result = await eventsService.findPlaces(val);
      this.setFiltersLocation(result.data);
      const current = result.data.find((item) => item.Name === val);
      this.filters.location.val = current.Name;
    },
    async parseQuery() {
      const { query } = this.$route;
      Object.keys(query).forEach((key) => {
        if (key === 'place') {
          const val = query[key];
          this.setPlace(val);
        } else {
          const item = this.filters[key];
          if (item) {
            let val = query[key];

            if (item.type
              && item.type === 'array'
              && !Array.isArray(val)
            ) {
              val = [val];
            }

            if (typeof item.defaultVal === 'boolean') {
              val = val === true || val === 'true';
            }

            this.filters[key].val = val;
          }
        }
      });

      const data = this.getValues();

      this.$emit('parsed', { parse: true, ...data });
    },
    async findLocation(query) {
      this.isNoResultSearchLocation = query.length < 3;
      if (query.length > 2) {
        this.loadingLocation = true;
        try {
          const result = await eventsService.findPlaces(query);
          this.setFiltersLocation(result.data);
        } catch (error) {
          console.error(error);
        } finally {
          this.loadingLocation = false;
        }
      } else if (query) {
        this.filters.location.options = [];
      }
    },
  },
  watch: {
    eventsFilters: {
      handler(val) {
        if (val) {
          this.setFilters(val);
          this.parseQuery();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.isMounted = true;
  },
  created() {
    const state = localStorage.getItem('eventsFilterState');
    if (state) {
      this.state = JSON.parse(state);
    } else {
      localStorage.setItem('eventsFilterState', JSON.stringify(this.state));
    }
  },
  beforeDestroy() {
    if (this.isActive) {
      this.close();
    }
  },
};
</script>

<style lang="scss">
  .events-filters {
    width: 285px;

    @media (max-width: breakpoint(lg, max)) {
      position: fixed;
      top: 0;
      right: 0;
      height: 100%;
      z-index: 1005;
      width: 100%;
      overflow-x: hidden;
      overflow-scrolling: touch;
      pointer-events: none;
      opacity: 0;

      &_active {
        pointer-events: auto;
        opacity: 1;
      }
    }

    @media (min-width: breakpoint(md)) {

      &_active {
        transition: $transition-default opacity;
      }
    }

    &__container {
      @include base-sm-shadow;
      margin-left: auto;
      width: 600px;
      max-width: 100%;
      min-height: 100%;
      position: relative;

      @media (max-width: breakpoint(lg, max)) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      @media (max-width: breakpoint(md, max)) {
        border-radius: 0;
        width: 100%;
        transform: none;
      }
    }

    &__overlay {
      position: fixed;
    }

    &__footer {
      padding: 12px 16px;
    }
  }

  .rating-slider {

    .vue-slider-dot-disabled {
      display: none;
    }
  }

  .events-filters__date-mobile .mobile-modal__body {
    max-width: 100%;
    overflow: auto;
  }
</style>
