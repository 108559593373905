<template>
  <div
    class="base-dropdown"
    ref="dropdown"
    :class="{
      'active': isActive || isHovered,
      'base-dropdown_wide': !autoWidth,
    }"
    :style="{position: strategy}"
  >
    <div
      class="base-dropdown__overlay"
      v-if="!hideOverlay"
    ></div>
    <div
      class="base-dropdown__block"
      ref="block"
    >
      <div class="base-dropdown__container">
        <div class="base-dropdown__menu">
          <slot name="items"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core/lib/popper-lite';
import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow';

export default {
  name: 'Dropdown',
  props: {
    trigger: String,
    triggerId: String,
    listeners: {
      type: String,
      default: 'click',
    },
    placement: {
      type: String,
      default: 'bottom-start',
    },
    hideOverlay: Boolean,
    noListeners: Boolean,
    usePopper: {
      type: Boolean,
      default: true,
    },
    autoWidth: Boolean,
    strategy: {
      type: String,
      default: 'absolute',
    },
    hoverTimeoutDelay: {
      type: Number,
      default: 300,
    },
    unhoverTimeoutDelay: {
      type: Number,
      default: 600,
    },
  },
  data() {
    return {
      isActive: false,
      isHovered: false,
      hoverTimeout: null,
      unhoverTimeout: null,
    };
  },
  computed: {
    isShown() {
      return this.isActive || this.isHovered;
    },
  },
  methods: {
    open(e) {
      this.preventEvent(e);
      this.isActive = true;
      this.resetHover();
      this.$emit('toggle', true);
    },
    close() {
      this.isActive = false;
      this.resetHover();
      this.$emit('toggle', false);
    },
    toggle(e) {
      this.preventEvent(e);
      this.isActive = !this.isActive;
      this.resetHover();
      const delay = this.isActive ? 10 : 0;
      setTimeout(() => {
        this.$emit('toggle', this.isActive);
      }, delay);
    },
    preventEvent(e) {
      if (e && e.preventDefault) {
        e.preventDefault();
      }
    },
    resetHover() {
      this.isHovered = false;
      clearTimeout(this.hoverTimeout);
      clearTimeout(this.unhoverTimeout);
    },
    hover() {
      if (!this.isHovered) {
        this.hoverTimeout = setTimeout(() => {
          this.isHovered = true;
        }, this.hoverTimeoutDelay);
      }
      clearTimeout(this.unhoverTimeout);
    },
    unhover() {
      if (this.isHovered) {
        this.unhoverTimeout = setTimeout(() => {
          this.isHovered = false;
        }, this.unhoverTimeoutDelay);
      }
      clearTimeout(this.hoverTimeout);
    },
    setPosition() {
      this.popperInstance = createPopper(this.triggerEl, this.dropdownBlock, {
        placement: this.placement,
        modifiers: [preventOverflow],
        strategy: this.strategy,
      });
    },
    checkClick(e) {
      if (!this.triggerEl.contains(e.target) && this.triggerEl !== document.activeElement) {
        this.close();
      }
    },
    defineListeners() {
      const vm = this;
      const clickEvent = this.listeners.indexOf('click') !== -1;
      const hoverEvent = this.listeners.indexOf('hover') !== -1 && !this.$root.isTouch;
      const focusEvent = this.listeners.indexOf('focus') !== -1;

      if (this.triggerEl && clickEvent) {
        this.triggerEl.addEventListener('click', vm.toggle);
      }
      if (this.triggerEl && hoverEvent) {
        this.triggerEl.addEventListener('mouseenter', this.hover);
        this.triggerEl.addEventListener('mouseleave', this.unhover);
      }
      if (this.triggerEl && focusEvent) {
        this.triggerEl.addEventListener('focus', this.open);
      }

      if (this.dropdown && clickEvent) {
        this.dropdown.addEventListener('click', vm.close);
      }
      if (this.dropdown && hoverEvent) {
        this.dropdown.addEventListener('mouseenter', this.hover);
        this.dropdown.addEventListener('mouseleave', this.unhover);
      }
    },
    getRefComponent(parent) {
      return parent && parent.$refs[this.trigger];
    },
    getTriggerEl() {
      if (this.triggerId) {
        return document.getElementById(this.triggerId);
      }
      const ref = this.getRefComponent(this.$parent);
      if (ref && ref.$el) {
        return ref.$el;
      }
      return ref;
    },
  },
  watch: {
    isShown(val) {
      if (!this.noListeners) {
        document.removeEventListener('click', this.checkClick);
      }
      if (val) {
        if (this.usePopper) {
          this.setPosition();
        }
        if (!this.noListeners) {
          document.addEventListener('click', this.checkClick);
        }
        if (this.triggerEl) {
          this.triggerEl.classList.add('has-dropdown');
        }
        return;
      }
      this.triggerEl.classList.remove('has-dropdown');
      if (!val && this.popperInstance) {
        this.popperInstance.destroy();
        this.popperInstance = null;
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.triggerEl = this.getTriggerEl();
      this.dropdown = this.$refs.dropdown;
      this.dropdownBlock = this.$refs.block;
      if (!this.noListeners) {
        this.defineListeners();
      }
    }, 1000);
  },
  beforeDestroy() {
    if (this.noListeners) {
      return;
    }
    if (this.triggerEl) {
      this.triggerEl.removeEventListener('click', this.toggle);
      this.triggerEl.removeEventListener('mouseenter', this.hover);
      this.triggerEl.removeEventListener('mouseleave', this.unhover);
    }
    if (this.dropdown) {
      this.dropdown.removeEventListener('click', this.close);
      this.dropdown.removeEventListener('mouseenter', this.hover);
      this.dropdown.removeEventListener('mouseleave', this.unhover);
    }
  },
};
</script>

<style lang="scss">
@import "./Dropdown";
</style>
