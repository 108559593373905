import Vue from 'vue';
import VueRouter from 'vue-router';
import Meta from 'vue-meta';
import Home from '../views/Home.vue';

Vue.use(VueRouter);
Vue.use(Meta);

const routes = [
  {
    path: '/events',
    alias: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/event/:code',
    component: () => import(/* webpackChunkName: 'event' */ '../views/Event.vue'),
    children: [
      {
        name: 'Event',
        path: '',
        component: () => import(/* webpackChunkName: 'event-overview' */ '../views/Event/Overview.vue'),
      },
      {
        name: 'Members',
        path: 'startlist',
        component: () => import(/* webpackChunkName: 'event-members' */ '../views/Event/Members.vue'),
      },
    ],
  },
  {
    path: '/Results',
    name: 'Results',
    component: () => import(/* webpackChunkName: 'results' */'../views/Results.vue'),
  },
  {
    path: '/series/:seriesCode',
    component: () => import(/* webpackChunkName: 'series' */'../views/Series/Index.vue'),
    children: [
      {
        name: 'Series',
        path: '',
        component: () => import(/* webpackChunkName: 'series-about' */ '../views/Series/_About.vue'),
      },
      {
        name: 'SeriesEvents',
        path: 'events',
        component: () => import(/* webpackChunkName: 'series-events' */ '../views/Series/_Events.vue'),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      const header = document.querySelector('.rr-header');
      const offset = header ? header.offsetHeight : 0;
      return {
        selector: to.hash,
        offset: { x: 0, y: offset },
      };
    }
    if (to.name === from.name) {
      return savedPosition;
    }
    return savedPosition || { x: 0, y: 0 };
  },
});

export default router;
