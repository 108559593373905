function getFile(fileName, tenant) {
  try {
    // eslint-disable-next-line global-require,import/no-dynamic-require
    const data = require(`./${tenant || 'rr'}/${fileName}`);
    return data?.default || null;
  } catch {
    return null;
  }
}

function getData(fileName) {
  return getFile(fileName, window.RR_APP_TENANT) || getFile(fileName);
}

export default getData;
