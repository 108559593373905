const state = {
  alertsTop: [],
  alertsBottom: [],
  alertsId: 0,
};

const actions = {
  // eslint-disable-next-line no-shadow
  addAlert({ state }, alert) {
    state.alertsId += 1;
    const id = state.alertsId;
    if (alert.pos === 'bottom') {
      state.alertsBottom.push({ id, alert });
      return;
    }
    state.alertsTop.push({ id, alert });
  },
  // eslint-disable-next-line no-shadow
  removeAlert({ state }, id) {
    state.alertsTop = state.alertsTop.filter((m) => m.id !== id);
    state.alertsBottom = state.alertsBottom.filter((m) => m.id !== id);
  },
  addSomethingWrongAlert({ dispatch }, text) {
    dispatch('addAlert', {
      type: 'danger',
      text: text || 'Что-то пошло не так. Попробуйте снова',
      pos: 'bottom',
      noButton: true,
      noIcon: true,
    });
  },
  addSuccessAlert({ dispatch }, text) {
    dispatch('addAlert', {
      type: 'success',
      text,
      pos: 'bottom',
      button: { name: '' },
      noIcon: true,
    });
  },
};

// eslint-disable-next-line import/prefer-default-export
export default {
  namespaced: true,
  state,
  actions,
};
