<template>
  <div
    class="sort"
    :class="{'sort_disabled': isDisabled}"
  >
    <div class="sort__container">
      <div class="sort__wrapper d-md-none">
        <div class="sort__item">
          <Select
            v-model="select"
            :value="select"
            :options="options"
            class="color-primary"
            cls="multiselect_dropdown-auto"
            type="simple"
            size="sm"
          ></Select>
        </div>
      </div>
      <div class="sort__wrapper d-none d-md-flex">
        <div
          class="sort__item"
          v-for="(sortOption, i) in options"
          :key="i"
        >
          <a
            v-if="sortOption.href"
            class="sort__link"
            :href="sortOption.href || '#'"
            :data-index="i"
          >
            <span v-html="sortOption.name"></span>
          </a>
          <a
            v-else
            class="sort__link"
            :class="{
              'active': active === sortOption.id,
              'reverse':
                sortOption.order
                && active === sortOption.id
                && sortOption.order !== order
            }"
            :data-index="i"
            @click.prevent="changeSort(sortOption.id, sortOption.order, sortOption)"
          >
            <span v-html="sortOption.name"></span>
            <span
              class="sort__link-arr"
              v-if="sortOption.order && active === sortOption.id"
              v-html="
                sortOption.order !== order
                ? ` ${arrows(sortOption.order === 'asc')[0]}`
                : ` ${arrows(sortOption.order === 'asc')[1]}`
              "
            ></span>
          </a>
        </div>
      </div>
      <div
        class="sort__extra"
        v-if="$scopedSlots.extra"
      >
        <slot name="extra"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Select from '@/components/Select.vue';

export default {
  name: 'Sort',
  components: {
    Select,
  },
  props: {
    isDisabled: Boolean,
    active: [Number, String],
    options: Array,
    order: String,
  },
  computed: {
    select: {
      get() {
        return this.active;
      },
      set(id) {
        this.changeSort(id);
      },
    },
  },
  methods: {
    arrows(asc) {
      return asc ? ['&#8595', '&#8593'] : ['&#8593', '&#8595'];
    },
    changeSort(id, initialOrder, fullObject = null) {
      if (fullObject && fullObject.onClick) {
        fullObject.onClick();
        return;
      }
      let order = initialOrder;
      if (this.active === id) {
        if (!initialOrder) {
          return;
        }
        order = this.order === 'asc' ? 'desc' : 'asc';
      }
      order = order || null;
      this.$emit('change', { id, order });
    },
  },
};
</script>

<style lang="scss" scoped>
  .sort {
    @include text-md;
    font-weight: $font-weight-semibold;
    padding: 12px 0;
    background-color: $color-black-4;
    border-radius: 4px;
    position: relative;

    &__container {
      display: flex;
      padding: 0 20px;
      margin: 0 -10px;
      height: 32px;
    }

    &__wrapper {
      display: flex;
      align-items: center;
      flex: 1 1 100%;
    }

    &__item {
      margin: 0 10px;
    }

    &__link {
      text-decoration: none;
      color: inherit;
      white-space: nowrap;

      &.active, &.reverse {
        color: $color-primary;
      }

      .sort_disabled & {
        pointer-events: none;
        color: $color-black-25;
      }
    }

    &__link-arr {
      display: none;

      .active &, .reverse & {
        display: inline;
      }

      .sort_disabled & {
        display: none;
      }
    }

    &__extra {
      flex: 0 0 auto;
      margin: 0 10px;
    }
  }
</style>
