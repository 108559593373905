<template>
  <vue-ads-pagination
    class="pagination"
    :page="page"
    :total-items="totalItems"
    :items-per-page="itemsPerPage"
    :max-visible-pages="maxVisiblePages"
    @page-change="changePage"
  >
    <template
      slot="buttons"
      slot-scope="props"
    >
      <div class="pagination__row">
        <button
          class="pagination__button"
          :class="{
              'active': button.active,
              'pagination__prev': button.title === 'previous',
              'pagination__next': button.title === 'next'}
            "
          v-for="(button, key) in props.buttons"
          :key="key"
          @click="changePage(button.page)"
          type="button"
          :disabled="button.disabled"
        >
          <icon
            v-if="button.title === 'previous'"
            icon-name="left"
          ></icon>
          <icon
            v-else-if="button.title === 'next'"
            icon-name="right"
          ></icon>
          <template v-else>{{ button.html }}</template>
        </button>
      </div>
    </template>
  </vue-ads-pagination>
</template>

<script>
import VueAdsPagination from 'vue-ads-pagination';

export default {
  name: 'Pagination',
  components: {
    VueAdsPagination,
  },
  props: {
    page: Number,
    totalItems: Number,
    itemsPerPage: Number,
  },
  data() {
    return {
      maxVisiblePages: 3,
    };
  },
  methods: {
    updateMaxVisiblePages() {
      this.maxVisiblePages = window.innerWidth > 767 ? 3 : 1;
    },
    changePage(page) {
      if (this.page === page) {
        return;
      }
      this.$emit('change', page);
    },
  },
  mounted() {
    this.updateMaxVisiblePages();
    window.addEventListener('resize', () => {
      if (this.debouncedUpdate) {
        clearTimeout(this.debouncedUpdate);
      }
      this.debouncedUpdate = setTimeout(this.updateMaxVisiblePages, 200);
    });
  },
};
</script>

<style lang="scss">
  .pagination {
    @include text-sm;

    @media (min-width: breakpoint(md)) {
      @include text-md;
    }

    .vue-ads-leading-loose {
      display: none;
    }

    &__row {
      display: flex;
      align-items: center;
      justify-content: center;

      &_mobile {
        justify-content: space-between;
      }
    }

    &__button {
      font-family: inherit;
      display: block;
      min-width: 32px;
      height: 32px;
      line-height: 0;
      text-align: center;
      border-radius: 50%;
      background: none;
      border: 0;
      user-select: none;
      color: inherit;
      padding: 0 4px;
      margin: 0 4px;

      @media (min-width: breakpoint(md)) {
        min-width: 40px;
        height: 40px;
        margin: 0 6px;
      }

      .no-touch &:not(:disabled):hover {
        background-color: $color-black-9;
      }

      &.active,
      .no-touch &.active:hover {
        background-color: $color-primary-2;
        color: $color-primary;
        font-weight: $font-weight-semibold;
      }

      &:disabled {
        cursor: default;
      }
    }

    &__prev, &__next {
      margin: 0 8px;

      @media (min-width: breakpoint(md)) {
        margin: 0 18px;
      }

      svg {
        display: block;
        width: 12px;
        height: 12px;
        margin: auto;

        @media (min-width: breakpoint(md)) {
          width: 18px;
          height: 18px;
        }
      }

      &:disabled {
        background-color: $color-black-4;
        color: $color-black-25;
      }
    }

    &__prev {
      margin-left: 0;
    }

    &__next {
      margin-right: 0;
    }
  }
</style>
