<template>
  <main class="layout__main">
    <div class="container">
      <banner
        v-if="theme === 'rr'"
        class="banner-block_top"
        :content="bannerTop"
        :contentMobile="bannerTopMobile"
      />
      <draft-alert
        class="mt-0"
      />
    </div>
    <section class="section mt-0">
      <div class="container">
        <div class="home-grid grid grid_h-center">
          <div class="grid__col grid__col_lg_stretch">
            <div class="section__header">
              <div
                class="home-header grid"
                :class="{ 'home-header-rzd': isRzd }">
                <div class="home-filters-title grid__col grid__col_auto">
                  <h1 :class="{ 'event-title': ['rzd', 'legkovrace', 'tula'].includes(theme) }">
                    {{ pageTitle }}
                    <span
                      v-if="showEventsCount"
                      class="event-title-count">
                      ({{ totalCount }})
                    </span>
                  </h1>
                </div>
                <div class="home-filters-btn grid__col grid__col_auto d-lg-none">
                  <Button
                    class="rr-button_shadow"
                    variant="clear"
                    icon-left="filter"
                    :text="$t('filters.title')"
                    @click="() => {$refs.filters.open()}"
                  >
                     <badge
                       class="badge_circle badge_pos_right"
                       :text="filtersCount"
                       type="danger"
                       size="sm"
                       v-show="filtersCount"
                     ></badge>
                  </Button>
                </div>
                <div class="home-search grid__col grid__col_md-stretch">
                  <events-search></events-search>
                </div>
              </div>
              <div class="section__subheader">
                <sort
                  :active="sort"
                  :options="sortOptions"
                  @change="changeSort"
                >
                  <div
                    v-if="viewOption"
                    slot="extra"
                    class="rr-buttons-group rr-buttons-group_solid d-none d-md-block"
                  >
                    <Button
                      v-for="viewOption in viewOptions"
                      :key="viewOption.id"
                      :variant="view === viewOption.id ? 'ghost' : 'secondary'"
                      size="sm"
                      :class="{
                        'rr-buttons-group__active': view === viewOption.id,
                      }"
                      :text="viewOption.name"
                      style="background: white"
                      @click="changeView(viewOption.id)"
                    ></Button>
                  </div>
                  <div
                    slot="extra"
                    class="rr-buttons-group rr-buttons-group_solid d-md-none"
                  >
                    <Button
                      v-for="viewOption in viewOptions"
                      :key="viewOption.id"
                      :variant="view === viewOption.id ? 'ghost' : 'secondary'"
                      shape="square"
                      size="sm"
                      :class="{
                        'rr-buttons-group__active': view === viewOption.id,
                      }"
                      :icon-left="viewOption.icon"
                      style="background: white"
                      @click="changeView(viewOption.id)"
                    ></Button>
                  </div>
                </sort>
              </div>
            </div>
            <!-- Map view : BEGIN -->
            <EventsMap
              v-show="view === 'map'"
              ref="eventsMap"
              :eventsType="sort"
              @updateQuery="setQuery"
            >
            </EventsMap>
            <!-- Map view : END -->
            <!-- List view : BEGIN -->
            <div v-show="view === 'list'">
              <SeriesList
                v-show="eventsType === 'series'"
                ref="seriesList"
                :banner="bannerContent"
                :bannerMobile="bannerContentMobile"
                @updateQuery="setQuery"
              ></SeriesList>
              <EventsList
                v-show="eventsType !== 'series'"
                ref="eventsList"
                :eventsType="sort"
                :banner="bannerContent"
                :bannerMobile="bannerContentMobile"
                @updateQuery="setQuery"
                @updateTotalCount="setTotalCount"
              >
              </EventsList>
            </div>
            <!-- List view : END -->
          </div>
          <div class="grid__col grid__col_lg-auto">
            <div class="nmo-block block d-none d-lg-block" v-if="showNbdBlock">
              <a :href="env.VUE_APP_URL + 'nbd'" target="_blank">
                <img
                  width='275'
                  height='100'
                  :src="nbdImg"
                  alt="НБД"
                >
              </a>
            </div>
            <events-filters
              ref="filters"
              :eventsType="eventsType"
              @change="changeFilters"
              @parsed="changeFilters"
            ></events-filters>
          </div>
        </div>
      </div>
    </section>
    <offline-reg-modal></offline-reg-modal>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import EventsList from '@/components/EventsList.vue';
import EventsMap from '@/components/EventsMap.vue';
import EventsSearch from '@/components/EventsSearch.vue';
import EventsFilters from '@/components/EventsFilters.vue';
import Sort from '@/components/Sort.vue';
import Badge from '@/components/Badge.vue';
import OfflineRegModal from '@/components/OfflineRegModal.vue';
import DraftAlert from '@/components/DraftAlert.vue';
import Banner from '@/components/Banner.vue';
import SeriesList from '@/sections/SeriesList.vue';
import getData from '@/data';

export default {
  name: 'Home',
  components: {
    EventsList,
    EventsMap,
    SeriesList,
    EventsSearch,
    EventsFilters,
    Sort,
    Badge,
    OfflineRegModal,
    DraftAlert,
    Banner,
  },
  data() {
    return {
      env: process.env,
      isMounted: false,
      sort: null,
      sortOptions: getData('events-nav.js') || [],
      view: 'list',
      viewOption: null,
      // viewOptions: [
      //   {
      //     id: 'list',
      //     name: this.$t('events.nav.listView'),
      //     icon: 'unordered-list',
      //   },
      //   {
      //     id: 'map',
      //     name: this.$t('events.nav.mapView'),
      //     icon: 'map',
      //   },
      // ],
      filtersId: +new Date(),
      filters: {},
      filtersQuery: {},
      totalCount: 0,
    };
  },
  computed: {
    isRzd() {
      return this.theme === 'rzd';
    },
    pageTitle() {
      const titlesDict = {
        rzd: this.$t('events.altTitle'),
        legkovrace: this.$t('events.altTitle'),
        tula: this.$t('events.altTitle'),
        default: this.$t('events.title'),
      };

      return titlesDict[this.theme] ?? titlesDict.default;
    },
    showEventsCount() {
      const showOnTenants = ['rzd'];
      return this.totalCount > 0 && showOnTenants.includes(this.theme);
    },
    showNbdBlock() {
      const themes = ['rr', 'rzd'];
      return themes.includes(this.theme);
    },
    ...mapGetters('settings', ['theme']),
    bannerTop() {
      return window.eventsBannerTop || null;
    },
    bannerTopMobile() {
      return window.eventsBannerTopMobile || null;
    },
    bannerContent() {
      return window.eventsBannerContent || null;
    },
    bannerContentMobile() {
      return window.eventsBannerContentMobile || null;
    },
    filtersCount() {
      return this.isMounted ? this.$refs.filters.filtersCount : 0;
    },
    nbdImg() {
      let { locale } = this.$i18n;
      locale = locale !== 'ru' ? `-${locale}` : '';
      return `${this.env.VUE_APP_URL}Content/images/events/nrm-banner${locale}.jpg`;
    },
    eventsType() {
      switch (this.sort) {
        case '0':
          return 'current';
        case '4':
          return 'series';
        case '3':
          return 'virtual';
        default:
          return 'current';
      }
    },
  },
  methods: {
    changeSort(value) {
      const isResults = value.id === 'results';
      const currentHostname = window.location.hostname;
      const predProdHostname = 'rr-dev.ru';
      const isDev = [predProdHostname, 'localhost'].includes(currentHostname);
      const resultDevUrl = `https://results.${predProdHostname}`;

      if (isResults && isDev) {
        window.location.href = resultDevUrl;
        return;
      }

      if (isResults) {
        window.location.href = 'https://results.samarkandmarathon.uz/';
        return;
      }

      this.sort = value.id;
      this.$refs.filters.reset();
    },
    changeFilters(data) {
      this.filtersId += 1;

      this.filters = data.value || {};
      this.filtersQuery = data.query || {};

      setTimeout(() => {
        if (this.view === 'map') {
          this.$refs.eventsMap.onFiltersChange(data, this.filtersId);
        } else if (this.eventsType === 'series') {
          this.$refs.seriesList.onFiltersChange(data, this.filtersId);
          window.scrollTo(0, 0);
        } else {
          this.$refs.eventsList.onFiltersChange(data, this.filtersId);
          window.scrollTo(0, 0);
        }
      });
    },
    changeView(view) {
      if (this.view === view) {
        return;
      }
      this.view = view;
      const isSeries = this.eventsType === 'series';
      this.$refs.eventsMap.toggle(view === 'map', this.filtersId);
      this.$refs.seriesList.toggle(view === 'list' && isSeries, this.filtersId);
      this.$refs.eventsList.toggle(view === 'list' && !isSeries, this.filtersId);
    },
    getFiltersRequestParams() {
      let filters = {};
      if (Object.keys(this.filters)) {
        const f = this.filters;
        filters = {
          DisciplinesCodes: f.discipline ? [f.discipline] : null,
          DateFrom: f.dateFrom,
          DateTo: f.dateTo,
          StarRaitings: f.starsArr || [],
          SportSeriesCode: f.series,
          ApprovedStarRaitingOnly: f.confirmedRating,
          RrRecomended: f.champs && f.champs.indexOf('rr') !== -1,
          InSportmasterChampionship: f.champs && f.champs.indexOf('sm') !== -1,
          NationalMovementOnly: f.nmo,
          CityCode: f.location,
          Place: f.place,
          OnlyWithOpenRegistration: f.hasPlaces,
          FromAge: f.ageFrom,
          ToAge: f.ageTo,
          ResultsCalculated: false,
          OnlyWithAdmissions: f.fastId,
          SortRule: {
            Type: 0,
            Direction: 1,
          },
        };
      }
      return filters;
    },
    setQuery() {
      setTimeout(() => {
        const query = this.filtersQuery;

        const { page } = this.$refs.eventsList.pagination;
        if (page > 0) {
          query.p = page;
        }

        if (this.sort !== '0') {
          query.e = +this.sort;
        }

        const beneficiaryId = this.$route.query.beneficiaryId ? { beneficiaryId: this.$route.query.beneficiaryId } : null;

        this.$router.replace({ query: { ...query, ...beneficiaryId } });
      });
    },
    parseQuery() {
      const { query } = this.$route;

      if (query.p) {
        this.$refs.eventsList.pagination.page = +query.p;
      }

      if (query.e) {
        this.sort = query.e;
      }
    },
    setTotalCount(value) {
      this.totalCount = value;
    },
  },
  mounted() {
    this.sort = this.sortOptions[0]?.id || null;
    this.isMounted = true;
    this.parseQuery();
  },
};
</script>

<style lang="scss">
@import "@rr-component-library/common/src/scss/vars";
@import "@rr-component-library/common/src/scss/mixins";
@import "@rr-component-library/common/src/scss/utils";

  .home-grid {

    @media (min-width: breakpoint(lg)) {
      flex-wrap: nowrap;
    }

    > .grid__col {
      min-width: 0;
    }
  }
  .home-header {
    align-items: center;
    gap: 8px 16px;
  }

  .home-filters-title {
    margin-right: auto;

    &__count {
      color: $color-black-45;
    }
  }

  .home-filters-btn {

    @media (min-width: breakpoint(md)) {
      order: 1;
    }

    .badge {
      margin: -4px;
    }

    @media (max-width: 374px) {
      .rr-button__icon {
        display: none;
      }
    }
  }

  .home-search {

    @media (max-width: breakpoint(lg, max)) {
      .events-search {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .nmo-block {
    width: 100%;
    max-width: 285px;
    padding: 0;
    margin-bottom: 24px;

    img {
      display: block;
      margin: 0 auto;
    }
  }

  .event-title,
  .event-title-count {
    font-size: 24px;
    line-height: 30px;
  }

  .event-title-count {
    color: $color-black-45;
  }

  @media (max-width: breakpoint(sm)) {
    .event-title,
    .event-title-count {
      font-size: 20px;
      line-height: 24px;
    }

    .home-header-rzd {
      display: grid;
      grid-template-columns: 1fr 48px;
      gap: 16px 8px;
      align-items: flex-start;
      grid-template-areas:
        'title title'
        'search filters'
      ;
    }

    .home-header-rzd .home-filters-btn .rr-button__text {
      display: none;
    }

    .home-header-rzd .home-filters-btn .rr-button__icon {
      margin-right: 0;
    }

    .home-header-rzd .home-filters-btn .rr-button {
      padding: 16px;
    }

    .home-header-rzd .home-filters-btn {
      padding: 0;
    }

    .home-header-rzd .events-search__container {
      padding: 6px;
    }

    .home-header-rzd .events-search {
      margin-top: 0;
    }

    .home-filters-title {
      grid-area: title;
    }

    .home-filters-btn {
      grid-area: filters;
    }

    .home-search {
      grid-area: search;
    }
  }
</style>
