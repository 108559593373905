<template>
  <div class="layout__footer">
    <component :is="currentTenantFooter" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Footer from '@/components/rr/Footer/Footer.vue';

export default {
  name: 'TenantFooter',
  components: {
    Footer,
  },
  computed: {
    ...mapGetters('settings', ['theme']),
    currentTenantFooter() {
      const tenants = {
        rr: () => import('@/components/rr/Footer/Footer.vue'),
        topliga2: () => import('@/components/topliga2/Footer/Footer.vue'),
        bfla: () => import('@/components/bfla/Footer/Footer.vue'),
        steelcharacter: () => import('@/components/steelcharacter/Footer/Footer.vue'),
        samarkand: () => import('@/components/samarkand/Footer/Footer.vue'),
        rzd: () => import('@/components/rzd/Footer/Footer.vue'),
        pionercup: () => import('@/components/pionercup/Footer/Footer.vue'),
        legkovrace: () => import('@/components/legkovrace/Footer/Footer.vue'),
        tula: () => import('@/components/tula/Footer/Footer.vue'),
      };

      return tenants[this.theme] || Footer;
    },
  },
};
</script>
