<template>
  <div class="alerts">
    <div class="alerts__wrap">
      <div class="alerts__container">
        <transition
          name="alerts"
          tag="div"
          mode="out-in"
        ></transition>
        <alert
          v-if="alertsTop[0]"
          :key="alertsTop[0].id"
          :id="alertsTop[0].id"
          :type="alertsTop[0].alert.type"
          :text="alertsTop[0].alert.text"
          :icon="alertsTop[0].alert.icon"
          :cls="'alert_shadow'"
          :isAlert="true"
          :button="alertsTop[0].alert.noButton ? false : alertsTop[0].alert.button || {text: 'OK'}"
          :buttonsCls="alertsTop[0].alert.buttonsCls"
          :noIcon="alertsTop[0].alert.noIcon"
        ></alert>
      </div>
      <div class="alerts__container alerts__container alerts__container_bottom">
        <transition
          name="alerts"
          tag="div"
          mode="out-in"
        >
          <alert
            v-if="alertsBottom[0]"
            :key="alertsBottom[0].id"
            :id="alertsBottom[0].id"
            :type="alertsBottom[0].alert.type"
            :text="alertsBottom[0].alert.text"
            :icon="alertsBottom[0].alert.icon"
            :cls="'alert_shadow alert_bottom'"
            pos='bottom'
            :isAlert="true"
            :button="alertsBottom[0].alert.noButton
              ? false : alertsBottom[0].alert.button
              || {text: 'OK'}"
            :buttonsCls="alertsBottom[0].alert.buttonsCls"
            :noIcon="alertsBottom[0].alert.noIcon"
          ></alert>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Alert from '@/components/Alert.vue';

export default {
  name: 'Alerts',
  components: {
    Alert,
  },
  computed: {
    ...mapState('alerts', ['alertsTop', 'alertsBottom']),
  },
};
</script>

<style scoped lang="scss">
  .alert {
    transition: all 0.2s;
  }

  .alerts-enter,
  .alerts-leave-to {
    opacity: 0;
    transform: translateY(-16px);

    &.alert_bottom {
      transform: translateY(16px);
    }
  }

  .alerts-leave-active {
    position: absolute;
    z-index: -1;
  }
</style>
