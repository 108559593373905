<template>
  <div class="filter-item-ext">
    <div
      class="filter-item-ext__container"
      @click="hasFilter ? open() : null"
    >
      <div
        class="filter-item-ext__name"
        v-html="name"
      ></div>
      <div
        class="filter-item-ext__value"
        :class="{'color-primary' : isNotDefault}"
        v-if="$scopedSlots.value"
      >
        <slot name="value" :isNotDefault="isNotDefault"></slot>
      </div>
      <div class="filter-item-ext__icon" v-if="hasFilter">
        <icon icon-name="right"></icon>
      </div>
    </div>
    <div class="filter-item-ext__body" v-if="$scopedSlots.inlineFilter">
      <slot name="inlineFilter"></slot>
    </div>
    <mobile-modal
      v-if="hasFilter"
      ref="filterModal"
      :backIcon="true"
      :name="name"
      @close="$emit('close')"
      @cancel="cancel"
      @reset="reset"
      @apply="$emit('apply')"
      :hideFooter="!(changed || isChanged)"
      :showResetBtn="true"
    >
      <slot name="filter"></slot>
    </mobile-modal>
  </div>
</template>

<script>
import MobileModal from '@/components/MobileModal.vue';
import { clone, isEqual } from 'lodash';

export default {
  name: 'FilterItemExtended',
  components: {
    MobileModal,
  },
  props: {
    name: String,
    value: [String, Array, Object],
    defaultValue: {
      type: [String, Number, Array, Object, Boolean],
      default: undefined,
    },
    changed: Boolean,
  },
  data() {
    return {
      oldValue: null,
    };
  },
  computed: {
    hasFilter() {
      return this.$scopedSlots.filter;
    },
    isNotDefault() {
      if (this.defaultValue === undefined) {
        return false;
      }
      return !isEqual(this.defaultValue, this.value);
    },
    isChanged() {
      return !isEqual(this.oldValue, this.value);
    },
  },
  methods: {
    open() {
      this.$emit('open');
      this.$refs.filterModal.open();
      this.oldValue = clone(this.value);
      setTimeout(() => {
        this.$emit('opened');
      }, 100);
    },
    cancel() {
      this.$emit('input', this.oldValue);
    },
    reset() {
      if (this.defaultValue !== undefined) {
        this.$emit('input', this.defaultValue);
      }
      this.$emit('reset');
    },
  },
};
</script>

<style lang="scss">
  .filter-item-ext {
    border-bottom: 1px solid $color-black-9;

    &__container {
      display: flex;
      align-items: center;
      min-height: 48px;
      padding: 8px 16px;
    }

    &__name {
      flex: 1 1 100%;
      min-width: 0;
      margin-right: 16px;
    }

    &__value {
      flex: 0 0 auto;
      color: $color-black-45;
      max-width: 65%;
      text-align: right;
    }

    &__icon {
      flex: 0 0 auto;
      width: 18px;
      height: 18px;
      opacity: 0.45;
      margin-left: 8px;

      svg {
        display: block;
      }
    }

    &__body {
      padding: 0 16px 16px;
    }

    &__body-item {
      margin-top: 16px;

      &:first-child {
        margin-top: 0;
      }
    }
  }
</style>
