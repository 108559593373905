import moment from 'moment';
import i18n from '@/i18n';

function isCurrentYear(momentDate) {
  if (!momentDate) {
    return false;
  }
  return momentDate.isSame(moment(), 'year');
}

function getShortMonth(momentDate) {
  if (!momentDate) {
    return null;
  }
  return momentDate.format('MMMM').slice(0, 3);
}

function formatDateRange([start, end]) {
  if (!start) {
    return null;
  }

  const startDate = moment(start);
  const endDate = end ? moment(end) : null;
  const yearFormat = isCurrentYear(startDate) ? '' : '[&nbsp;]YYYY';

  // One day
  if (!endDate || startDate.isSame(endDate, 'day')) {
    return startDate.format(`DD[&nbsp;]MMMM${yearFormat}`);
  }

  // One month
  if (startDate.isSame(endDate, 'month')) {
    const startFormat = 'DD';
    const endFormat = `DD[&nbsp;]MMMM${yearFormat}`;
    return `${startDate.format(startFormat)}–${endDate.format(endFormat)}`;
  }

  // One year
  if (startDate.isSame(endDate, 'year')) {
    const startFormat = `DD[&nbsp;][${getShortMonth(startDate)}]`;
    const endFormat = `DD[&nbsp;][${getShortMonth(endDate)}]${yearFormat}`;
    return `${startDate.format(startFormat)} – ${endDate.format(endFormat)}`;
  }

  const startFormat = `DD[&nbsp;][${getShortMonth(startDate)}]${yearFormat}`;
  const endFormat = `DD[&nbsp;${getShortMonth(endDate)}]${yearFormat}`;
  return `${startDate.format(startFormat)} – ${endDate.format(endFormat)}`;
}

function getDateObj(date) {
  if (!date) {
    return null;
  }
  const momentDate = moment(date);
  return {
    day: momentDate.date(),
    month: momentDate.month() + 1,
    year: momentDate.year(),
  };
}

function getCroppedImg(url, [width, height]) {
  if (!url) {
    return null;
  }

  const w = width || '-';
  const h = height || '-';

  return url.replace('/-x-/', `/${w}x${h}/`);
}

function formatFileSize(bytes, decimalPoint) {
  if (!bytes || bytes < 0) {
    return null;
  }
  const sizes = i18n.t('units.fileSize');
  if (bytes === 0) {
    return `0 ${sizes[0]}`;
  }
  const k = 1000;
  const dm = decimalPoint || 2;
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export {
  formatDateRange,
  getDateObj,
  getCroppedImg,
  formatFileSize,
};
