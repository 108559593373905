import Vue from 'vue';
import Vuex from 'vuex';

import settings from './settings.module';
import alerts from './alerts.module';
import events from './events.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    settings,
    alerts,
    events,
  },
});
